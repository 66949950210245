/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CategoryBannerLevel } from "@core/models/banner/banner.model";
import { CategoriesService } from "@core/services/api/categories.service";
import { ErrorMessage } from "@modules/error-message";
import { TranslateService } from "@ngx-translate/core";

export interface CategorySelected {
    id: number;
    name: string;
    nameEN: string;
    parentId?: number;
    mainParentId?: number;
}

@Component({
    selector: "app-multi-select-tree",
    templateUrl: "./multi-select-tree.component.html",
    styleUrls: ["./multi-select-tree.component.scss"],
})
export class MultiSelectTreeComponent implements OnInit {
    @Output() categoriesChange = new EventEmitter<any>();
    @Input() oldData: CategoryBannerLevel | undefined;
    @Input() isDisable = false;
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    @Input() isDisableErrorMassage: boolean = false;

    @Input()
    set error(value: any) {
        this.isError = false;
        this.errorMessage = "";

        if (value) {
            this.isError = true;
            this.errorMessage = new ErrorMessage().getErrorMessage(value);
        }
    }

    categoryLv1List: any[] = [];
    categoryLv2List: { [key: number]: any[] } = {};
    categoryLv3List: { [key: number]: any[] } = {};

    seleteLevel1Names: CategorySelected[] = [];
    seleteLevel2Names: CategorySelected[] = [];
    seleteLevel3Names: CategorySelected[] = [];

    private _selectedLevel1Ids: number[] = [];
    private _selectedLevel2Ids: number[] = [];
    private _selectedLevel3Ids: number[] = [];

    loadOldDataLevel1 = true;
    loadOldDataLevel2 = true;
    loadOldDataLevel3 = true;

    dropdownStatus: { [key: string]: boolean } = {};
    dropdownOpen = false;
    searchControl: FormControl = new FormControl("");
    isError = false;
    errorMessage = "";
    currentLang = "th";

    constructor(
        private categoriesService: CategoriesService,
        private translate: TranslateService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.currentLang = this.translate.currentLang;
        this.getListLv1({});
        let level3ParentLevel1Id: number[] = [];
        let level3ParentLevel2Id: number[] = [];

        if (this.oldData) {
            if (this.oldData.level3Ids.length > 0) {
                this._selectedLevel3Ids = this.oldData.level3Ids;
                this.categoriesService
                    .getCategoryLevel3ByIDs(this.oldData.level3Ids)
                    .subscribe({
                        next: (res) => {
                            this.oldData?.level3Ids.forEach((idCategory) => {
                                const categoryData = res.find(
                                    (x) => x.id === idCategory,
                                );
                                if (categoryData) {
                                    const data: CategorySelected = {
                                        id: categoryData.id,
                                        name: categoryData.name,
                                        nameEN: categoryData.nameEN,
                                    };
                                    this.seleteLevel3Names.push(data);
                                    level3ParentLevel1Id.push(
                                        categoryData.mainParentId ?? -1,
                                    );
                                    level3ParentLevel2Id.push(
                                        categoryData.parentId ?? -1,
                                    );
                                    level3ParentLevel1Id =
                                        level3ParentLevel1Id.filter(
                                            (x) => x != -1,
                                        );
                                    level3ParentLevel2Id =
                                        level3ParentLevel2Id.filter(
                                            (x) => x != -1,
                                        );
                                }
                            });
                            if (level3ParentLevel2Id.length > 0) {
                                level3ParentLevel2Id =
                                    level3ParentLevel2Id.filter(
                                        (item, index) =>
                                            level3ParentLevel2Id.indexOf(
                                                item,
                                            ) === index,
                                    );
                                level3ParentLevel2Id.map((categoryId) => {
                                    this.toggleDropdown(2, categoryId);
                                });
                            }
                            this.loadOldDataLevel3 = false;
                        },
                        error: () => {
                            this.loadOldDataLevel3 = false;
                        },
                    });
            } else {
                this.loadOldDataLevel3 = false;
            }

            if (this.oldData.level2Ids.length > 0) {
                this._selectedLevel2Ids = this.oldData.level2Ids;
                const listLevel2NeedToLoad =
                    this.oldData.level2Ids.concat(level3ParentLevel2Id);
                this.categoriesService
                    .getCategoryLevel2ByIDs(listLevel2NeedToLoad)
                    .subscribe({
                        next: (res) => {
                            this.oldData?.level2Ids.forEach((idCategory) => {
                                const categoryData = res.find(
                                    (x) => x.id === idCategory,
                                );
                                if (categoryData) {
                                    const data: CategorySelected = {
                                        id: categoryData.id,
                                        name: categoryData.name,
                                        nameEN: categoryData.nameEN,
                                    };
                                    // const key = `${1}-${categoryData.mainParentId}`;
                                    // this.dropdownStatus[key] = !this.dropdownStatus[key];
                                    // this.categoryLv2List[categoryData.mainParentId ?? 0] = res;
                                    level3ParentLevel1Id.push(
                                        categoryData.parentId ?? -1,
                                    );
                                    level3ParentLevel1Id =
                                        level3ParentLevel1Id.filter(
                                            (x) => x != -1,
                                        );
                                    this.seleteLevel2Names.push(data);
                                }

                                this.loadOldDataLevel2 = false;
                            });

                            if (level3ParentLevel1Id.length > 0) {
                                level3ParentLevel1Id =
                                    level3ParentLevel1Id.filter(
                                        (item, index) =>
                                            level3ParentLevel1Id.indexOf(
                                                item,
                                            ) === index,
                                    );
                                level3ParentLevel1Id.map((categoryId) => {
                                    this.toggleDropdown(1, categoryId);
                                });
                            }
                        },
                        error: () => {
                            this.loadOldDataLevel2 = false;
                        },
                    });
            } else {
                this.loadOldDataLevel2 = false;
            }

            if (this.oldData.level1Ids.length > 0) {
                this._selectedLevel1Ids = this.oldData.level1Ids;
                const listLevel1NeedToLoad =
                    this.oldData.level1Ids.concat(level3ParentLevel1Id);
                listLevel1NeedToLoad.forEach((idCategory) => {
                    const categoryData = this.categoryLv1List.find(
                        (x) => x.id === idCategory,
                    ) as CategorySelected | undefined;
                    if (categoryData) {
                        const data: CategorySelected = {
                            id: categoryData.id,
                            name: categoryData.name,
                            nameEN: categoryData.nameEN,
                        };
                        this.seleteLevel1Names.push(data);
                    }
                });
                this.loadOldDataLevel1 = false;
            } else {
                this.loadOldDataLevel1 = false;
            }
        }
    }

    async getListLv1(params: any): Promise<void> {
        this.categoriesService.getcategory(params).subscribe({
            next: (res) => {
                this.categoryLv1List = res;
            },
        });
    }

    getListLv2(id: number, params: any): void {
        this.categoriesService.getcategoryLV1(id, params).subscribe({
            next: (res) => {
                this.categoryLv2List[id] = res;
                if (this.selectedLevel1Ids.includes(id)) {
                    this.selectChildCategories(id);
                }
            },
        });
    }

    getListLv3(idLevel1: number, idLevel2: number, params: any): void {
        if (idLevel1 === -1 || idLevel2 === -1) return;
        this.categoriesService
            .getcategoryLV2(idLevel1, idLevel2, params)
            .subscribe({
                next: (res) => {
                    this.categoryLv3List[idLevel2] = res;
                    if (this.selectedLevel2Ids.includes(idLevel2)) {
                        this.selectLevel3Categories(idLevel2);
                    }
                },
            });
    }

    toggleSelection(level: number, itemId: number): void {
        switch (level) {
            case 1:
                this.toggleLevel1Selection(itemId);
                break;
            case 2:
                this.toggleLevel2Selection(itemId);
                break;
            case 3:
                this.toggleLevel3Selection(itemId);
                break;
            default:
                return;
        }
        this.categoriesChange.emit(this.getCategories());
    }

    toggleLevel1Selection(level1Id: number): void {
        const index = this.selectedLevel1Ids.indexOf(level1Id);
        if (index > -1) {
            this.selectedLevel1Ids.splice(index, 1);
            this.deselectChildCategories(level1Id);
        } else {
            this.selectedLevel1Ids.push(level1Id);
            this.selectChildCategories(level1Id);
        }
    }

    toggleLevel2Selection(level2Id: number): void {
        const index = this.selectedLevel2Ids.indexOf(level2Id);
        const level1Id = this.findParentLevel1Id(level2Id);

        if (index > -1) {
            this.selectedLevel2Ids.splice(index, 1);
            this.deselectLevel3Categories(level2Id);
            if (!this.areAllLevel2Selected(level1Id)) {
                const parentIndex = this.selectedLevel1Ids.indexOf(level1Id);
                if (parentIndex > -1) {
                    this.selectedLevel1Ids.splice(parentIndex, 1);
                }
            }
        } else {
            this.selectedLevel2Ids.push(level2Id);
            this.selectLevel3Categories(level2Id);
            if (this.areAllLevel2Selected(level1Id)) {
                if (this.selectedLevel1Ids.indexOf(level1Id) === -1) {
                    this.selectedLevel1Ids.push(level1Id);
                }
            }
        }
    }

    toggleLevel3Selection(level3Id: number): void {
        const index = this.selectedLevel3Ids.indexOf(level3Id);
        const level2Id = this.findParentLevel2Id(level3Id);
        const level1Id = this.findParentLevel1Id(level2Id);

        if (index > -1) {
            this.selectedLevel3Ids.splice(index, 1);
            if (!this.areAllLevel3Selected(level2Id)) {
                const parentIndex = this.selectedLevel2Ids.indexOf(level2Id);
                if (parentIndex > -1) {
                    this.selectedLevel2Ids.splice(parentIndex, 1);
                }
                if (!this.areAllLevel2Selected(level1Id)) {
                    const grandParentIndex =
                        this.selectedLevel1Ids.indexOf(level1Id);
                    if (grandParentIndex > -1) {
                        this.selectedLevel1Ids.splice(grandParentIndex, 1);
                    }
                }
            }
        } else {
            this.selectedLevel3Ids.push(level3Id);
            if (this.areAllLevel3Selected(level2Id)) {
                if (this.selectedLevel2Ids.indexOf(level2Id) === -1) {
                    this.selectedLevel2Ids.push(level2Id);
                }
            }
            if (this.areAllLevel2Selected(level1Id)) {
                if (this.selectedLevel1Ids.indexOf(level1Id) === -1) {
                    this.selectedLevel1Ids.push(level1Id);
                }
            }
        }
    }

    selectChildCategories(level1Id: number): void {
        if (!this.categoryLv2List[level1Id]) {
            this.getListLv2(level1Id, {});
        }
        if (this.categoryLv2List[level1Id]) {
            for (const level2 of this.categoryLv2List[level1Id]) {
                if (this.selectedLevel2Ids.indexOf(level2.id) === -1) {
                    this.selectedLevel2Ids.push(level2.id);
                }
                if (!this.categoryLv3List[level2.id]) {
                    this.getListLv3(level1Id, level2.id, {});
                }
                if (this.categoryLv3List[level2.id]) {
                    for (const level3 of this.categoryLv3List[level2.id]) {
                        if (this.selectedLevel3Ids.indexOf(level3.id) === -1) {
                            this.selectedLevel3Ids.push(level3.id);
                        }
                    }
                }
            }
        }
    }

    deselectChildCategories(level1Id: number): void {
        if (this.categoryLv2List[level1Id]) {
            for (const level2 of this.categoryLv2List[level1Id]) {
                const level2Index = this.selectedLevel2Ids.indexOf(level2.id);
                if (level2Index > -1) {
                    this.selectedLevel2Ids.splice(level2Index, 1);
                }
                if (this.categoryLv3List[level2.id]) {
                    for (const level3 of this.categoryLv3List[level2.id]) {
                        const level3Index = this.selectedLevel3Ids.indexOf(
                            level3.id,
                        );
                        if (level3Index > -1) {
                            this.selectedLevel3Ids.splice(level3Index, 1);
                        }
                    }
                }
            }
        }
    }

    selectLevel3Categories(level2Id: number): void {
        if (this.categoryLv3List[level2Id]) {
            for (const level3 of this.categoryLv3List[level2Id]) {
                if (this.selectedLevel3Ids.indexOf(level3.id) === -1) {
                    this.selectedLevel3Ids.push(level3.id);
                }
            }
        }
    }

    deselectLevel3Categories(level2Id: number): void {
        if (this.categoryLv3List[level2Id]) {
            for (const level3 of this.categoryLv3List[level2Id]) {
                const level3Index = this.selectedLevel3Ids.indexOf(level3.id);
                if (level3Index > -1) {
                    this.selectedLevel3Ids.splice(level3Index, 1);
                }
            }
        }
    }

    toggleDropdown(level: number, itemId: number): void {
        const key = `${level}-${itemId}`;
        this.dropdownStatus[key] = !this.dropdownStatus[key];
        if (this.dropdownStatus[key]) {
            if (level === 1 && !this.categoryLv2List[itemId]) {
                this.getListLv2(itemId, {});
            } else if (level === 2 && !this.categoryLv3List[itemId]) {
                const parentLevel1Id = this.findParentLevel1Id(itemId);
                this.getListLv3(parentLevel1Id, itemId, {});
            }
        }
    }

    isDropdownOpen(level: number, itemId: number): boolean {
        const key = `${level}-${itemId}`;
        return this.dropdownStatus[key] || false;
    }

    findParentLevel1Id(level2Id: number): number {
        for (const key in this.categoryLv2List) {
            if (
                this.categoryLv2List[key].some(
                    (category) => category.id === level2Id,
                )
            ) {
                return parseInt(key, 10);
            }
        }
        return -1;
    }

    findParentLevel2Id(level3Id: number): number {
        for (const key in this.categoryLv3List) {
            if (
                this.categoryLv3List[key].some(
                    (category) => category.id === level3Id,
                )
            ) {
                return parseInt(key, 10);
            }
        }
        return -1;
    }

    areAllLevel2Selected(level1Id: number): boolean {
        if (!this.categoryLv2List[level1Id]) {
            return false;
        }
        for (const level2 of this.categoryLv2List[level1Id]) {
            if (this.selectedLevel2Ids.indexOf(level2.id) === -1) {
                return false;
            }
        }
        return true;
    }

    areAllLevel3Selected(level2Id: number): boolean {
        if (!this.categoryLv3List[level2Id]) {
            return false;
        }
        for (const level3 of this.categoryLv3List[level2Id]) {
            if (this.selectedLevel3Ids.indexOf(level3.id) === -1) {
                return false;
            }
        }
        return true;
    }

    toggleDropdownVisibility(): void {
        if (!this.isDisable) {
            this.dropdownOpen = !this.dropdownOpen;
        }
    }

    getCategories(): any {
        return {
            level1Ids: this.selectedLevel1Ids,
            level2Ids: this.selectedLevel2Ids,
            level3Ids: this.selectedLevel3Ids,
        };
    }

    isLevel1Indeterminate(level1Id: number): boolean {
        const level2Categories = this.categoryLv2List[level1Id];
        if (!level2Categories) return false;
        const selectedLevel2Count = level2Categories.filter((level2) =>
            this.selectedLevel2Ids.includes(level2.id),
        ).length;
        return (
            selectedLevel2Count > 0 &&
            selectedLevel2Count < level2Categories.length
        );
    }

    isLevel2Indeterminate(level2Id: number): boolean {
        const level3Categories = this.categoryLv3List[level2Id];
        if (!level3Categories) return false;
        const selectedLevel3Count = level3Categories.filter((level3) =>
            this.selectedLevel3Ids.includes(level3.id),
        ).length;
        return (
            selectedLevel3Count > 0 &&
            selectedLevel3Count < level3Categories.length
        );
    }

    get selectedLevel1Ids(): number[] {
        if (!this.loadOldDataLevel1) {
            this.getNameLevel1();
        }
        return this._selectedLevel1Ids;
    }

    set selectedLevel1Ids(value: number[]) {
        this._selectedLevel1Ids = value;
    }

    get selectedLevel2Ids(): number[] {
        if (!this.loadOldDataLevel2) {
            this.getNameLevel2();
        }
        return this._selectedLevel2Ids;
    }

    set selectedLevel2Ids(value: number[]) {
        this._selectedLevel2Ids = value;
    }

    get selectedLevel3Ids(): number[] {
        if (!this.loadOldDataLevel3) {
            this.getNameLevel3();
        }
        return this._selectedLevel3Ids;
    }

    set selectedLevel3Ids(value: number[]) {
        this._selectedLevel3Ids = value;
    }

    getNameLevel1() {
        this.seleteLevel1Names = [];
        if (
            this._selectedLevel1Ids.length === 0 ||
            this.categoryLv1List.length === 0
        )
            return;
        this._selectedLevel1Ids.forEach((idCategory) => {
            const categoryData = this.categoryLv1List.find(
                (x) => x.id === idCategory,
            );
            const data: CategorySelected = {
                id: categoryData.id,
                name: categoryData.name,
                nameEN: categoryData.nameEN,
            };
            this.seleteLevel1Names.push(data);
        });
    }

    getNameLevel2() {
        this.seleteLevel2Names = [];
        if (
            this._selectedLevel2Ids.length === 0 ||
            Object.keys(this.categoryLv2List).length === 0
        )
            return;
        this._selectedLevel2Ids.forEach((idCategory) => {
            const parentId = this.findParentLevel1Id(idCategory);
            if (parentId != -1) {
                const categoryData = this.categoryLv2List[parentId].find(
                    (x) => x.id === idCategory,
                );
                const data: CategorySelected = {
                    id: categoryData.id,
                    name: categoryData.name,
                    nameEN: categoryData.nameEN,
                };
                this.seleteLevel2Names.push(data);
            }
        });
    }

    getNameLevel3() {
        this.seleteLevel3Names = [];
        if (
            this._selectedLevel3Ids.length === 0 ||
            Object.keys(this.categoryLv3List).length === 0
        )
            return;
        this._selectedLevel3Ids.forEach((idCategory) => {
            const parentId = this.findParentLevel2Id(idCategory);
            if (parentId != -1) {
                const categoryData = this.categoryLv3List[parentId].find(
                    (x) => x.id === idCategory,
                );
                const data: CategorySelected = {
                    id: categoryData.id,
                    name: categoryData.name,
                    nameEN: categoryData.nameEN,
                };
                this.seleteLevel3Names.push(data);
            }
        });
    }

    getTotalCount(): number {
        let count = 0;

        count += this.seleteLevel1Names.length;

        count += this.seleteLevel2Names.filter(
            (item) =>
                !this.areAllLevel2Selected(this.findParentLevel1Id(item.id)),
        ).length;

        count += this.seleteLevel3Names.filter(
            (item) =>
                !this.areAllLevel3Selected(this.findParentLevel2Id(item.id)),
        ).length;

        return count;
    }
}
