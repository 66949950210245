import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    forwardRef,
    ViewEncapsulation,
    OnChanges,
    ViewChild,
    ElementRef,
    SimpleChanges,
    Output,
    EventEmitter,
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
    selector: "app-upload-image",
    templateUrl: "./upload-image.component.html",
    styleUrls: ["./upload-image.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UploadImageComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class UploadImageComponent
    implements OnInit, OnDestroy, ControlValueAccessor, OnChanges
{
    imageUrl$ = new BehaviorSubject<string | string[] | null>(null);

    @Input() set imageValue(data: File) {
        const reader = new FileReader();
        reader.onload = () => {
            this.imageUrl$.next(reader.result as string);
        };
        reader.readAsDataURL(data);
    }
    @Input() multiple = false;
    @Input() width = "100px";
    @Input() height = "100px";
    @Input() isDisabled = false;
    @Input() removable = false;
    @Input() id = "image-input"; // Ensure unique IDs if multiple instances are used
    @Input() isCancel = false;
    @Input() setDefaultImage = null;
    @Input() defaultImage = "";
    @Output() filesUploaded: EventEmitter<File[]> = new EventEmitter<File[]>();

    @ViewChild("imageInput", { static: false }) imageInput: ElementRef =
        {} as ElementRef;

    form = new FormControl();
    subscription: Subscription = new Subscription();
    hasUploadedImages = false;

    onChange = (value: string) => {
        this.form.setValue(value);
    };
    onTouched = (value: string) => {
        this.form.setValue(value);
    };

    ngOnInit() {
        this.subscription = this.form.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                const value = this.form.value;
                this.onChange(value);
                this.writeValue(value);
            });
        this.imageUrl$.subscribe((images) => {
            this.hasUploadedImages = images !== null && images.length > 0;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["isCancel"] && this.isCancel) {
            this.removeImage();
            this.imageUrl$.next(this.defaultImage);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    writeValue<T>(value: T): void {
        this.form.setValue(value);
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    processFile(imageInput: HTMLInputElement): void {
        const files = imageInput.files;
        if (!files) return;

        this.filesUploaded.emit(Array.from(files));

        if (this.multiple) {
            // Handle multiple files
            const readers = Array.from(files).map((file) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(readers).then((images) => {
                this.imageUrl$.next(images); // Emit an array of image URLs
            });
        } else {
            // Handle a single file (just the first one if multiple are selected)
            const file = files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.imageUrl$.next([reader.result as string]); // Emit an array with a single image URL for consistency
            };
            reader.readAsDataURL(file);
        }
    }

    removeImage() {
        this.imageInput.nativeElement.value = "";
        this.imageUrl$.next(null);
        this.form.patchValue(null);
    }

    onClick() {
        if (this.isDisabled) {
            return;
        }
        this.imageInput.nativeElement.click();
    }

    ensureArray(value: string | string[] | null): string[] {
        if (Array.isArray(value)) {
            return value; // It's already an array, return as is
        } else if (typeof value === "string") {
            return [value]; // Wrap the string in an array
        } else {
            return []; // Null or undefined case, return an empty array
        }
    }
}
