/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { LabelMode } from "../_enums/label-mode.enum";
import { ConfigLabelMode } from "../_config/label.config";
import { DatepickerMode } from "../_enums/datepicker-mode.enum";
import { MatDatepicker } from "@angular/material/datepicker";
import { Moment } from "moment";
import {
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
    DateAdapter,
} from "@angular/material/core";
import {
    // APP_DATE_YEAR_FORMATS,
    AppDateYearAdapter,
} from "../config-year.format";

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { ErrorMessage } from "@modules/error-message";

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
const MY_FORMATS = {
    parse: {
        dateInput: "MMMM YYYY",
    },
    display: {
        dateInput: "input",
        monthYearLabel: "MMM YYYY",
    },
};

@Component({
    selector: "app-datepicker-year",
    templateUrl: "./datepicker-year.component.html",
    styleUrls: ["./datepicker-year.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerYearComponent),
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: "th-TH" },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        {
            provide: DateAdapter,
            useClass: AppDateYearAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class DatepickerYearComponent
    implements OnInit, OnDestroy, ControlValueAccessor
{
    isDiabled = false;
    @Input()
    set isDisabled(value: boolean) {
        if (value) {
            this.isDiabled = value;
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    @Input()
    set error(value: any) {
        this.isError = false;
        this.errorMessage = "";

        if (value) {
            this.isError = true;
            this.errorMessage = new ErrorMessage().getErrorMessage(value);
        }
    }

    @Input()
    set value(value: any) {
        this.form.setValue(value);
    }

    @Input() name = "";
    @Input() placeholder = "";
    @Input() type = "text";
    @Input() readonly = false;
    @Input() label = "";
    @Input()
    set labelMode(value: LabelMode) {
        this.mode = value;
    }
    @Input() typeMode: DatepickerMode = DatepickerMode.CALANDAR;

    DatepickerMode = DatepickerMode;

    LabelMode = LabelMode;
    mode: LabelMode = new ConfigLabelMode().default();

    form = new FormControl();
    subscription: Subscription = new Subscription();
    errorMessage = "";
    isRequired = false;
    isError = false;
    minDate = new Date();

    onChange = (value: string) => {
        return value;
    };
    onTouched = (value: string) => {
        return value;
    };

    ngOnInit() {
        this.subscription = this.form.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.onChange(
                    this.form.value
                        ? this.form.value.toJSON()
                        : this.form.value,
                );
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    writeValue(value: any): void {
        this.form.setValue(value ? new Date(value) : null);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        return isDisabled;
    }

    onkeyDown(event: Event) {
        event.preventDefault();
    }

    chosenYearHandler(
        normalizedYear: Moment,
        datepicker: MatDatepicker<Moment>,
    ) {
        this.form.setValue(normalizedYear);
        datepicker.close();
        // const ctrlValue = this.form.value;
        // ctrlValue.year(normalizedYear.year());
        // this.form.setValue(ctrlValue);
        // datepicker.close();
    }
}
