import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as XLSX from "xlsx";

export interface UploadFlag {
    idHtml: string;
    haveDownload?: boolean;
    showLoading: boolean;
    uploaded: boolean;
    fileName: string;
    fileSize: string;
    loading: number;
    errorSize: boolean;
    errorType: boolean;
    errorRequired?: boolean;
    header: string[];
    disabled?: boolean;
}

@Component({
    selector: "app-input-image-full-scale",
    templateUrl: "./input-image-full-scale.component.html",
    styleUrls: ["./input-image-full-scale.component.scss"],
})
export class InputImageFullScaleComponent implements OnInit {
    ngOnInit(): void {
        if (window.location.href.includes("view")) {
            this.uploadFlagData.disabled = true;
        }
    }
    @Output() inputDataFile: EventEmitter<HTMLInputElement> =
        new EventEmitter<HTMLInputElement>();
    @Output() clearFile: EventEmitter<void> = new EventEmitter<void>();
    @Output() downloadFile: EventEmitter<void> = new EventEmitter<void>();
    @Input() set uploadFlag(value: UploadFlag) {
        if (value) {
            this.uploadFlagData = value;
        }
    }
    @Input() imageSrc = "";
    @Input() placeholder = "";
    @Input() title = "";
    @Input() noExampleDownload = false;
    @Input() acceptType = "";
    @Input() imageType = false;

    uploadFlagData: UploadFlag = {
        idHtml: "",
        showLoading: false,
        uploaded: false,
        fileName: "",
        fileSize: "",
        loading: 0,
        errorSize: false,
        errorType: false,
        header: [],
    };

    processDataFile(value: HTMLInputElement) {
        this.inputDataFile.emit(value);
    }

    onClick() {
        const typeHeader = this.uploadFlagData.idHtml;
        const elementId_old = typeHeader;
        const element_old = document.getElementById(
            elementId_old.toString(),
        ) as HTMLInputElement | null;
        this.clearFileInput(element_old);
        const elementId = typeHeader;
        const element = document.getElementById(elementId.toString());
        if (element) {
            element.click();
        }
    }

    clearFileInput(documentInput: HTMLInputElement | null) {
        if (documentInput) documentInput.value = "";
    }

    onClear() {
        const typeHeader = this.uploadFlagData.idHtml;
        const elementId_old = typeHeader;
        const element_old = document.getElementById(
            elementId_old.toString(),
        ) as HTMLInputElement | null;
        this.clearFileInput(element_old);
        this.clearFile.emit();
    }

    exportExampleDataFile() {
        const headers = [this.uploadFlagData.header];
        const worksheet = XLSX.utils.aoa_to_sheet(headers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "FileExample.csv");
    }

    onDownloadFile() {
        this.downloadFile.emit();
    }
}
