<div [ngClass]="{ 'is-invalid': isError }">
    <div class="dropdown-container" (click)="toggleDropdownVisibility()" [ngClass]="{ 'disabled': isDisable }">
        <input type="text" [formControl]="searchControl" [disabled]="isDisable"
            [placeholder]="(selectedLevel1Ids.length + selectedLevel2Ids.length + selectedLevel3Ids.length === 0) ? ('FORM_BANNER.PROMOTION.CATEGORY_PLACEHOLDER'| translate) : ('FORM_BANNER.PROMOTION.SELECTED_PLACEHOLDER' | translate :{count:selectedLevel1Ids.length + selectedLevel2Ids.length + selectedLevel3Ids.length})"
            readonly>
        <div class="box">
            <img src="assets/icons/arrow-down-select.svg" *ngIf="!dropdownOpen" />
            <img src="assets/icons/arrow-up-select.svg" *ngIf="dropdownOpen" />
        </div>
    </div>
    <div *ngIf="!isDisableErrorMassage" class="pt-1" style="color: #dc3545; font-size: 14px;" >{{ errorMessage }}</div>
    <div class="h-100">
        <span class="text-options px-1 mr-1 fs-xs" *ngFor="let item of seleteLevel1Names; let i = index">
            {{currentLang === 'th' ? item.name: item.nameEN}}
        </span>
        <span *ngIf="seleteLevel1Names.length >= 0">
            <ng-container *ngFor="let item of seleteLevel2Names; let i = index">
                <span class="text-options px-1 mr-1 fs-xs" *ngIf="!areAllLevel2Selected(findParentLevel1Id(item.id))">
                    {{currentLang === 'th' ? item.name : item.nameEN}}
                </span>
            </ng-container>
        </span>
        <span *ngIf="seleteLevel1Names.length >= 0">
            <ng-container *ngFor="let item of seleteLevel3Names; let i = index">
                <span class="text-options px-1 mr-1 fs-xs" *ngIf="!areAllLevel3Selected(findParentLevel2Id(item.id))">
                    {{currentLang === 'th' ? item.name : item.nameEN}}
                </span>
            </ng-container>
        </span>
    </div>

    <div *ngIf="dropdownOpen" class="dropdown-content">
        <div *ngFor="let level1 of categoryLv1List">
            <div class="pt-2">
                <mat-checkbox (change)="toggleSelection(1, level1.id)" color="success"
                    [checked]="selectedLevel1Ids.includes(level1.id)"
                    [indeterminate]="isLevel1Indeterminate(level1.id)">
                    <div class="pt-2">
                        {{currentLang === 'th' ? level1.name : level1.nameEN }}
                    </div>
                </mat-checkbox>
                <button class="pt-2 pr-2" (click)="toggleDropdown(1, level1.id)" type="button"
                    *ngIf="!level1.isCanDelete">
                    <img src="assets/icons/arrow-down-select.svg" *ngIf="!isDropdownOpen(1, level1.id)" />
                    <img src="assets/icons/arrow-up-select.svg" *ngIf="isDropdownOpen(1, level1.id)" />
                </button>
            </div>
            <div class="pl-4" *ngIf="isDropdownOpen(1, level1.id)">
                <div *ngFor="let level2 of categoryLv2List[level1.id]">
                    <mat-checkbox (change)="toggleSelection(2, level2.id)" color="success"
                        [checked]="selectedLevel2Ids.includes(level2.id)"
                        [indeterminate]="isLevel2Indeterminate(level2.id)">
                        <div class="pt-2">
                            {{currentLang === 'th' ? level2.name : level2.nameEN}}
                        </div>
                    </mat-checkbox>
                    <button class="pt-2 pr-2" (click)="toggleDropdown(2, level2.id)" type="button"
                        *ngIf="!level2.isCanDelete">
                        <img src="assets/icons/arrow-down-select.svg" *ngIf="!isDropdownOpen(2, level2.id)" />
                        <img src="assets/icons/arrow-up-select.svg" *ngIf="isDropdownOpen(2, level2.id)" />
                    </button>
                    <div class="pl-4" *ngIf="isDropdownOpen(2, level2.id)">
                        <div *ngFor="let level3 of categoryLv3List[level2.id]">
                            <mat-checkbox (change)="toggleSelection(3, level3.id)" color="success"
                                [checked]="selectedLevel3Ids.includes(level3.id)">
                                <div class="pt-2">
                                    {{currentLang === 'th' ? level3.name : level3.nameEN }}
                                </div>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
