/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    forwardRef,
    ViewEncapsulation,
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
    selector: "app-upload-image-custom",
    templateUrl: "./upload-image-custom.component.html",
    styleUrls: ["./upload-image-custom.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UploadImageCustomComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class UploadImageCustomComponent
    implements OnInit, OnDestroy, ControlValueAccessor
{
    imageUrl$ = new BehaviorSubject<string | File | null>(null);

    @Input()
    set imageValue(data: File) {
        this.imageUrl$.next(data);
    }
    @Input() isDisabled = false;

    @Input() removable = false;

    @Input() id: string | number = "1";

    @Input() disabled = false;

    form = new FormControl();
    subscription: Subscription = new Subscription();

    onChange = (value: string) => {
        return value;
    };
    onTouched = (value: string) => {
        return value;
    };
    ngOnInit() {
        this.subscription = this.form.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                const value = this.form.value;
                this.onChange(value);
                this.writeValue(value);
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    writeValue(value: any): void {
        this.form.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean) {
        return isDisabled;
    }

    async processFile(imageInput: any) {
        const file: FileList = imageInput.files;

        await this.form.setValue(file);
    }

    onClick() {
        const elementId_old = this.id || "image-input";
        const element_old = document.getElementById(
            elementId_old.toString(),
        ) as HTMLInputElement | null;
        this.clearFileInput(element_old);
        const elementId = this.id || "image-input";
        const element = document.getElementById(elementId.toString());
        if (element) {
            element.click();
        }
    }

    clearFileInput(imageInput: HTMLInputElement | null) {
        // Reset the value of the file input to clear the selected files
        if (imageInput) imageInput.value = "";
    }
}
