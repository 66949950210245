/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from "@angular/common/http";
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
    forwardRef,
} from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { environment } from "@environments/environment";
import { ErrorMessage } from "@modules/error-message";
import { DropdownPosition } from "@ng-select/ng-select";
import {
    Observable,
    Subject,
    Subscription,
    concat,
    lastValueFrom,
    of,
} from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from "rxjs/operators";
import { LabelMode } from "../_enums/label-mode.enum";
import { group } from "@core/models/group.model";

@Component({
    selector: "app-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SelectComponent
    implements OnInit, OnDestroy, ControlValueAccessor
{
    @Input() bindLabel = "name";
    @Input() bindValue = "id";
    @Input() label = "";
    @Input() placeholder = "";
    @Input() submitted = false;
    @Input() required = false;
    @Input() clearable = true;
    @Input() searchable = true;
    @Input() hideSelected = true;
    @Input() limit = 1000;
    @Input() isCustomSubDistrict = false;
    @Input() customWidth = false;
    @Input() name = "";
    @Input() appendTo = "";
    @Input() fieldName = "";
    @Input() disabledLabel = false;
    @Input() positionDropdown: DropdownPosition = "auto";

    @Input()
    set labelMode(value: LabelMode) {
        this.mode = value;
    }

    LabelMode = LabelMode;
    mode: LabelMode = LabelMode.DEFAULT;

    @Input()
    set params(value: any) {
        this.apiParams = value;

        this.setTypeahead(this.form.value);
    }

    @Input()
    set isDisabled(value: boolean) {
        if (value) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    @Input()
    set options(value: Array<any>) {
        this.options$ = of(value);
    }

    @Input()
    set url(value: string) {
        this.isTypeahead = true;
        this.apiUrl = environment.apiUrl + value;
    }

    @Input()
    set value(value: number) {
        this.setTypeahead(value);
        this.form.setValue(value);
    }

    @Input()
    set error(value: any) {
        this.isError = false;
        this.errorMessage = "";

        if (value) {
            this.isError = true;
            this.errorMessage = new ErrorMessage().getErrorMessage(value);
        }
    }

    @Output() valueChange = new EventEmitter();
    @Output() subDistrictChange = new EventEmitter();
    @Output() valueClear = new EventEmitter();

    form: FormControl = new FormControl("");
    subscription: Subscription = new Subscription();

    isRequired = false;
    isLoading = false;
    isTypeahead = false;
    isError = false;
    options$: Observable<any> = of([]);
    search$: Subject<string> = new Subject<string>();
    errorMessage = "";
    apiUrl = "";
    apiParams: any = {};

    onChange = (value: string) => {
        return value;
    };
    onTouched = (value: string) => {
        return value;
    };

    constructor(private http: HttpClient) {}

    ngOnInit() {
        if (window.location.href.includes("view")) {
            this.form.disable();
        }
        this.subscription = this.form.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.onChange(this.form.value);
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    writeValue(value: any): void {
        delete this.apiParams.id;
        this.setTypeahead(value);
        this.form.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        return isDisabled;
    }

    onSelect(value: any) {
        this.valueChange.emit(value);
    }

    setTypeahead(defaultId: number) {
        if (!this.isTypeahead) {
            return;
        }

        this.options$ = concat(
            this.setDefault(defaultId), // default items
            this.search$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.isLoading = true)),
                switchMap((keyword: string) =>
                    this.search(keyword).pipe(
                        map((response: any) => {
                            return response; // ส่งค่า response กลับไป
                        }),
                        catchError(() => of({ data: [] })), // empty list on error
                        tap(() => (this.isLoading = false)),
                    ),
                ),
            ),
        );
    }

    search(keyword: string): Observable<any> {
        let filters = {
            keywords: keyword ? keyword : "",
            limit: this.limit,
            keyword: "",
        };
        if (this.fieldName) {
            filters = {
                keyword: keyword ? keyword : "",
                limit: this.limit,
                keywords: "",
            };
        }

        if (this.apiParams) {
            filters = Object.assign({}, filters, this.apiParams);
        }
        return this.getHttp(filters);
    }

    getHttp(params?: any): Observable<any> {
        return this.http.get(this.apiUrl, { params }).pipe(
            map((response: any) => {
                if (this.apiUrl.includes("/admin/roles")) {
                    response.forEach((item: any) => {
                        if (item.name) {
                            const formattedName = item.name.replace(
                                /\s+/g,
                                "_",
                            );
                            if (group[formattedName as keyof typeof group]) {
                                item.name =
                                    group[formattedName as keyof typeof group];
                            }
                        }
                    });
                    return response;
                }
                if (this.fieldName && this.fieldName === "NoFieldName") {
                    return response;
                } else if (this.fieldName) {
                    return response[this.fieldName];
                } else {
                    // return response.data;
                    return response;
                }
            }),
            catchError(() => of({ data: [] })),
        );
    }

    async setDefault(id: number) {
        let results: any[] = [];
        const keyword = "";
        const tempResult = await lastValueFrom(this.search(keyword));

        if (this.fieldName) {
            results = tempResult;
        } else {
            results = tempResult;
        }

        if (id) {
            const isAlready: boolean =
                results.map((obj: any) => obj.id).indexOf(id) > -1;
            if (!isAlready) {
                this.apiParams.id = id;
                const defaultResult = await this.search(keyword).toPromise();
                const value = defaultResult ? defaultResult[0] : null;
                results.push(value);
                // if (defaultResult.data) {
                //     const value = defaultResult.data[0];
                //     results.push(value);
                // } else {
                //     const value = defaultResult[0];
                //     results.push(value);
                // }
            }
        }

        return results;
    }

    onClear() {
        this.valueClear.emit();
    }
}
