/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { environment } from '@environments/environment';
import { ErrorMessage } from '@modules/error-message';
import { DropdownPosition } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ConfigLabelMode } from '../_config/label.config';
import { LabelMode } from '../_enums/label-mode.enum';

@Component({
  selector: 'app-select-muli-stockist',
  templateUrl: './select-muli-stockist.component.html',
  styleUrls: ['./select-muli-stockist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMuliStockistComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectMuliStockistComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() submitted = false;
  @Input() required = false;
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() hideSelected = true;
  @Input() limit = 0;
  @Input() isCustomSubDistrict = false;
  @Input() customWidth = false;
  @Input() customTemplate: TemplateRef<any> | undefined;
  @Input() name = '';
  @Input() fieldName = '';
  @Input() oldData = []
  @Input() mainForm: FormGroup | undefined
  @Input() positionDropdown: DropdownPosition = 'auto'
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() isDisableErrorMassage: boolean = false

  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = new ConfigLabelMode().default();

  @Input()
  set params(value: any) {
    this.apiParams = value;

    this.setTypeahead(this.form.value);
  }

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set options(value: Array<any>) {

    this.options$ = of(value);
  }

  @Input()
  set url(value: string) {
    this.isTypeahead = true;
    this.apiUrl = environment.apiUrl + value;
  }

  @Input()
  set value(value: []) {
    this.setTypeahead(value);
    this.form.setValue(value);
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);
    }
  }

  @Output() valueChange = new EventEmitter();
  @Output() subDistrictChange = new EventEmitter();
  @Output() valueClear = new EventEmitter();

  form: FormControl = new FormControl('');
  subscription: Subscription = new Subscription;

  isRequired = false;
  isLoading = false;
  isTypeahead = false;
  isError = false;
  options$: Observable<any> = of([]);
  search$: Subject<string> = new Subject<string>();
  errorMessage = '';
  apiUrl = '';
  apiParams: any = {};
  isSelectId: any = []
  firstLoadOldData = true
  currentLang = 'th'

  onChange = (value: string) => {
    return value;
  };
  onTouched = (value: string) => {
    return value;
  };

  constructor(private http: HttpClient,
    private translate: TranslateService) { }

  ngOnInit() {
    if (window.location.href.includes("view")) {
      this.form.disable();
    }
    this.currentLang = this.translate.currentLang
    this.subscription = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.onChange(this.form.value);
    });

    if (this.mode === LabelMode.OUTSIDE && this.oldData) {
      this.getHttp({}).subscribe({
        next: (res) => {
          this.oldData.forEach(ids => {
            const Data = res.find((x: { id: any; }) => x.id === ids);
            if (Data) {
              this.isSelectId.push(Data);
            }
          });
          const haveId = this.isSelectId.map((x: any) => x.id);
          this.form.patchValue(haveId, { emitEvent: false, onlySelf: true });
          if (this.mainForm) {
            this.mainForm.markAsPristine();
          }
        }
      });
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    delete this.apiParams.id;
    this.setTypeahead(value);
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    return isDisabled;
  }

  onSelect(value: any) {
    if (this.mode === LabelMode.OUTSIDE) {
      this.isSelectId = value
    }
    this.valueChange.emit(value);
  }

  setTypeahead(defaultId: []) {
    if (!this.isTypeahead) {
      return;
    }

    this.options$ = concat(
      this.setDefault(defaultId), // default items
      this.search$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap((keyword: string) =>
          this.search(keyword).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.isLoading = false))
          )
        )
      )
    );
  }

  search(keyword: string): Observable<any> {
    let filters = {
      keyword: keyword ? keyword : '',
      // limit: this.limit ?? 10,
    };
    if (this.apiParams) {
      filters = Object.assign({}, filters, this.apiParams);
    }
    return this.getHttp(filters);
  }

  getHttp(params?: any): Observable<any> {
    return this.http.get(this.apiUrl, { params }).pipe(
      map((response: any) => {
        if (this.fieldName && this.fieldName === 'NoFieldName') {
          return response;
        } else if (this.fieldName) {
          return response[this.fieldName];
        } else {
          // return response.data;
          return response;
        }
      }),
      catchError(() => of({ data: [] })),
    );
  }

  async setDefault(id: []) {
    let results = [];
    results = await this.search('').toPromise();

    if (id.length > 0) {
      const isAlready: boolean = results.map((obj: any) => obj.id).indexOf(id) > -1;
      if (!isAlready) {
        this.apiParams.id = id;
        const defaultResult = await this.search('').toPromise();

        if (defaultResult.data) {
          const value = defaultResult.data[0];
          results.push(value);
        } else {
          const value = defaultResult[0];
          results.push(value);
        }


      }
    }

    return results;
  }

  onClear() {
    this.valueClear.emit();
  }
}
