import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    private config?: AppConfig;

    constructor(private http: HttpClient) {}

    loadConfig = async () =>
        this.http
            .get<AppConfig>("/assets/configs/app.json")
            .toPromise()
            .then((data) => {
                this.config = data;
            });

    getConfig = () => this.config;

    // create full api url
    getApiUrl = (path: string) => `${this.config?.apiUrl}${path}`;

    getAppVersion() {
        const version = localStorage.getItem("app-version");
        if (version) {
            return version;
        } else {
            return "v1";
        }
    }

    setAppVersion(version: string) {
        localStorage.setItem("app-version", version);
    }
}
