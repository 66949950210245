import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    getAppVersion() {
        const version = localStorage.getItem("app-version");
        if (version) {
            return version;
        } else {
            return "v1";
        }
    }

    setAppVersion(version: string) {
        localStorage.setItem("app-version", version);
    }
}