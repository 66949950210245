import { Component, Input } from "@angular/core";
import { ButtonTag } from "@core/models/shares/enum";
import { ModalConfig } from "@core/models/shares/modal.model";
import { IPayloadStockistProductBulk } from "@core/models/stockist-product/stockist-product.model";
import { StockistProductsService } from "@core/services/api/stockist-products.service";
import { UtilService } from "@core/services/shared/util.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from "xlsx";

@Component({
    selector: "app-modal-upload-stockist-product",
    templateUrl: "./modal-upload-stockist-product.component.html",
    styleUrls: ["./modal-upload-stockist-product.component.scss"],
})
export class ModalUploadStockistProductComponent {
    @Input() modalConfig: ModalConfig;
    @Input() id?: number;
    loading = 0;
    showLoading = false;
    uploaded = false;
    errorType = false;
    errorSize = false;
    errorUpload = false;
    errorEan = false;
    errorEanMessage = "";
    fileName = "";
    fileSize = "";

    constructor(
        private modalRef: NgbActiveModal,
        private stockistProductsService: StockistProductsService,
        private utilService: UtilService,
    ) {
        this.modalConfig = {
            colorButton: ButtonTag.Primary,
            title: "",
            subtitle: "",
            category_id: 0,
            category_name: "",
            message: "",
            confirmLabel: "",
            cancelLabel: "",
        };
    }

    close() {
        this.modalRef.close(false);
    }

    confirm() {
        // window.location.reload()
        this.modalRef.close(true);
    }

    onClick() {
        const elementId_old = "document-upload";
        const element_old = document.getElementById(
            elementId_old.toString(),
        ) as HTMLInputElement | null;
        this.clearFileInput(element_old);
        const elementId = "document-upload";
        const element = document.getElementById(elementId.toString());
        if (element) {
            element.click();
        }
    }

    clearFileInput(documentInput: HTMLInputElement | null) {
        if (documentInput) documentInput.value = "";
    }

    processFile(data: HTMLInputElement) {
        const dataToSend: IPayloadStockistProductBulk[] = [];
        this.fileName = "";
        this.fileSize = "";
        this.loading = 0;
        this.showLoading = true;
        const file: FileList | null = data.files;
        if (!file || !this.id) {
            return;
        }
        this.fileSize = this.utilService.convertFileSize(file[0].size);
        this.fileName = file[0].name;
        const acceptedFileTypes = [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        const maxFileSize = 100 * 1024 * 1024;

        if (file[0].size > maxFileSize) {
            this.errorSize = true;
        } else {
            this.errorSize = false;
        }

        if (!acceptedFileTypes.includes(file[0].type)) {
            this.errorType = true;
        } else {
            this.errorType = false;
        }
        if (this.errorSize || this.errorType) {
            this.uploaded = false;
            this.showLoading = false;
            return;
        }

        let hasColumnError = false;

        if (!this.errorSize && !this.errorType) {
            for (let i = 0; i < 100; i++) {
                setTimeout(async () => {
                    this.loading += 1;
                    if (this.loading === 80) {
                        const pureData = await file[0].arrayBuffer();
                        const readedData = XLSX.read(pureData);
                        const worksheet =
                            readedData.Sheets[readedData.SheetNames[0]];
                        const data = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                        });
                        const eanSet = new Set<string>();
                        const duplicateEans: string[] = [];
                        const expectedHeaderLength = 6;
                        let actualHeaderLength = 0;
                        let indexEan = -1;
                        let indexPrice = -1;
                        let indexMinimumUnit = -1;
                        // let indexMaximumUnit = -1
                        let indexStatus = -1;

                        data.forEach((data, index) => {
                            if (index === 0) {
                                const datac = data as string[];
                                indexEan = datac.findIndex(
                                    (name) => name.toLowerCase() === "ean",
                                );
                                indexPrice = datac.findIndex(
                                    (name) => name.toLowerCase() === "price",
                                );
                                indexMinimumUnit = datac.findIndex(
                                    (name) =>
                                        name.toLowerCase() === "minimumunit",
                                );
                                // indexMaximumUnit = datac.findIndex((name) => name.toLowerCase() === 'maximumunit')
                                indexStatus = datac.findIndex(
                                    (name) => name.toLowerCase() === "enabled",
                                );
                                if (
                                    indexEan === -1 ||
                                    indexPrice === -1 ||
                                    indexMinimumUnit === -1 ||
                                    indexStatus === -1
                                ) {
                                    this.errorType = true;
                                    return;
                                }
                            }
                            if (index !== 0) {
                                const datac = data as (
                                    | string
                                    | number
                                    | boolean
                                )[];
                                actualHeaderLength = datac.length;
                                if (
                                    actualHeaderLength !== expectedHeaderLength
                                ) {
                                    hasColumnError = true;
                                }

                                if (typeof datac[indexEan] === "number") {
                                    datac[indexEan] =
                                        datac[indexEan].toString();
                                }

                                if (
                                    datac[indexPrice] &&
                                    datac[indexMinimumUnit]
                                ) {
                                    const ean = datac[indexEan] as string;

                                    if (eanSet.has(ean)) {
                                        duplicateEans.push(ean);
                                    } else {
                                        eanSet.add(ean);

                                        dataToSend.push({
                                            ean: ean.replace(/\s/g, ""),
                                            price: datac[indexPrice] as number,
                                            minimumUnit: datac[
                                                indexMinimumUnit
                                            ] as number,
                                            // maximumUnit: datac[indexMaximumUnit] as number,
                                            status: this.checkStatusData(
                                                datac[indexStatus] as
                                                    | string
                                                    | boolean,
                                            ),
                                        });
                                    }
                                }
                            }
                        });

                        if (hasColumnError) {
                            this.errorUpload = true;
                            this.uploaded = false;
                            this.showLoading = false;
                            return;
                        }

                        if (duplicateEans.length > 0) {
                            const uniqueDuplicateEans = new Set<string>(
                                duplicateEans,
                            );
                            this.errorEan = true;
                            this.errorEanMessage = `${[...uniqueDuplicateEans].join(", ")}`;
                            this.uploaded = false;
                            this.showLoading = false;
                            return;
                        }

                        if (this.errorType === true) {
                            this.uploaded = false;
                            this.showLoading = false;
                            return;
                        }
                        this.stockistProductsService
                            .createUploadBulk(
                                this.id as number,
                                dataToSend.filter((x) => x.ean),
                            )
                            .subscribe({
                                next: () => {
                                    setTimeout(() => {
                                        this.loading = 100;
                                        this.uploaded = true;
                                        this.showLoading = false;
                                    }, 800);
                                },
                                error: () => {
                                    this.uploaded = false;
                                    this.showLoading = false;
                                    this.errorUpload = true;
                                },
                            });
                    }
                }, i * 20);
            }
        }
    }

    checkStatusData(status: boolean | string) {
        if (
            status === true ||
            status === "Active" ||
            status === "TRUE" ||
            status === "true"
        ) {
            return "Active";
        }
        return "Inactive";
    }
}
