import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [TranslateModule],
})
export class TranslationModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static forRoot(): any {
    throw new Error('Method not implemented.');
  }
}
