import { Pipe, PipeTransform } from "@angular/core";
import { SubRejectectCategory } from "@core/models/reject-category/category.model";
import { RetailerService } from "@core/services/api/retailer.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Pipe({
    name: "displaySubReject",
})
export class DisplaySubRejectName implements PipeTransform {
    constructor(private retailerService: RetailerService) {}

    transform(categoryId: number, subcategoryId: number): Observable<string> {
        return this.retailerService.getSubRejectCategory(categoryId).pipe(
            map((res) => {
                if (typeof res === "object" && res !== null) {
                    const subcategoryList: SubRejectectCategory[] =
                        Object.values(res);
                    const selectedSubcategory = subcategoryList.find(
                        (subcategory) => subcategory.id === subcategoryId,
                    );
                    return selectedSubcategory
                        ? selectedSubcategory.description
                        : "";
                } else {
                    return "";
                }
            }),
            catchError(() => {
                return of("");
            }),
        );
    }
}
