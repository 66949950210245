import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category, CategoryForProduct, CategoryParams } from '@core/models/category/category.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { CategorySelected } from '@modules/shared/multi-select-tree/multi-select-tree.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    baseURL = `${environment.apiUrl}/product/category`;
    constructor(private http: HttpClient) { }

    getcategory(params: CategoryParams): Observable<Category[]> {
        return this.http.get<Category[]>(this.baseURL, {
            params: ClearParams(params),
        });
    }

    get(id: number): Observable<Category> {
        return this.http.get<Category>(this.baseURL + '/' + id);
    }

    create(params: Category): Observable<Category> {
        return this.http.post<Category>(this.baseURL, params);
    }

    update(id: number, params: Category): Observable<Category> {
        return this.http.patch<Category>(this.baseURL + '/' + id, params);
    }

    delete(id: number) {
        return this.http.delete<Category>(this.baseURL + '/' + id);
    }

    createLV1(id: number, params: Category): Observable<Category> {
        return this.http.post<Category>(this.baseURL + '/' + id, params);
    }

    getcategoryLV1(id: number, params: CategoryParams): Observable<Category[]> {
        return this.http.get<Category[]>(this.baseURL + '/' + id, {
            params: ClearParams(params),
        });
    }

    updateLv1(id: number, lv_1_id: number, params: Category): Observable<Category> {
        return this.http.patch<Category>(this.baseURL + '/' + id + '/' + lv_1_id, params);
    }

    deleteLV1(id: number, lv_1_id: number) {
        return this.http.delete<Category>(this.baseURL + '/' + id + '/' + lv_1_id);
    }

    createLV2(id: number, lv_1_id: number, params: Category): Observable<Category> {
        return this.http.post<Category>(this.baseURL + '/' + id + '/' + lv_1_id, params);
    }

    getcategoryLV2(id: number, lv_1_id: number, params: CategoryParams): Observable<Category[]> {
        return this.http.get<Category[]>(this.baseURL + '/' + id + '/' + lv_1_id, {
            params: ClearParams(params),
        });
    }

    updateLv2(id: number, lv_1_id: number, lv_2_id: number, params: Category): Observable<Category> {
        return this.http.patch<Category>(this.baseURL + '/' + id + '/' + lv_1_id + '/' + lv_2_id, params);
    }

    deleteLV2(id: number, lv_1_id: number, lv_2_id: number) {
        return this.http.delete<Category>(this.baseURL + '/' + id + '/' + lv_1_id + '/' + lv_2_id);
    }

    getParentCategoryByLV3Id(id: number): Observable<CategoryForProduct> {
        return this.http.get<CategoryForProduct>(`${this.baseURL}/getParent-category-level3/${id}`);
    }


    getCategoryLevel2ByIDs(ids: number[]): Observable<CategorySelected[]> {
        return this.http.post<CategorySelected[]>(`${this.baseURL}/get-category-level2-by-list-ids`, { ids })
    }

    getCategoryLevel3ByIDs(ids: number[]): Observable<CategorySelected[]> {
        return this.http.post<CategorySelected[]>(`${this.baseURL}/get-category-level3-by-list-ids`, { ids })
    }

}
