// English
export const locale = {
    lang: 'en',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },

        LABEL_LIST: {
            NO_DATA: 'No data',
            VIEW_DETAIL: 'View Detail'
        },

        MODAL_IMAGE: {
            TITLE: 'Confirm Delete Image',
            MESSAGE: 'Are you sure about delete image ?',
            CONFIRM: 'Delete',
            CANCEL: 'Cancel'
        },

        PAGINATOR: {
            FIRST_PAGE: 'First page',
            ITEM_PER_PAGE: 'Items per page:',
            LAST_PAGE: 'Last page',
            NEXT_PAGE: 'Next page',
            PREVIOUS_PAGE: 'Previous page',
            FROM: 'From',
            ITEM: 'Items',
        },

        PRODUCT: {
            PRODUCT: 'Product',
            BRAND_TITLE: 'Brand',
            MANUFACTURER_TITLE: 'Manufacturer',
            CATEGORY_TITLE: 'Category',
            CREATE_PRODUCT: 'Create product',
            VIEW_PRODUCT: 'Product detail',
            CREATE_BRAND: 'Create brand',
            CREATE_MANUFACTURER: 'Create manufacturer',
        },

        VALIDATOR: {
            REQUIRED: 'Please fill out the list',
            MIN: 'Please enter a larger value',
            MAX: 'Please enter a value less than this',
            EMAIL: 'Invalid email',
            MUST_MATCH: "Passwords don't match",
            PATTERN: 'Please specify in letters (A-Z, a-z, ก-ฮ) and thai vowels only.',
            MIN_LENGTH: 'Input must be at least',
            MAX_LENGTH: 'Input must be at least',
            LENGTH_VAR: 'characters long',
            DUPLICATE: 'Duplicate Data',
            DUPLICATE_PHONE: 'This mobile number is already in use',
            DUPLICATE_TIME: 'Closing time is the same as opening time',
            SIZE_FILE: 'File is too large.',
            TYPE_FILE:
                'File has invalid extension. Only jpg. and png. are allowed.',
            UPLOAD_FILE: 'File upload fail.',
            REQUIRED_IMAGE: 'Please input image',
            REQUIRED_CATEGORY: 'Please select category',
            LIMIT_MIN: 'Please input image at least ',
            LIMIT_MAX: 'Please input image not more than ',
            UNIT_IMAGE: ' image',
            DUPLICATE_EAN: 'This EAN is already in use.',
            DATE_MAX: 'Please select a larger date.',
            PROMOTION_PRODUCT: 'Please select product.',
            PROMOTION_GIFT: 'Please select gift.',
            PROMOTION_OVERLAPPING_RANGE: 'Ranges should not overlap',
            PROMOTION_INVALID_TIER_ORDER: 'Value in this tier should not be greater than the value in the previous tier.',
            PROMOTION_INVALID_GAP: 'The minimum value of the this tier should be adjacent to the maximum value of the previous tier.',
            PROMOTION_INVALID_RANGE: 'Minimum value should not be greater than the maximum value',
            PROMOTION_MIN_EQUAL_MAX: 'Minimum value should not be equal to the maximum value',
            PROMOTION_INVALID_MAX: 'Maximum value should be a valid number or "Unlimit"',
            PROMOTION_INVALID_PRODUCT: 'Select products with the same price for tier promotion conditions.',
        },

        SIDE_BAR: {
            RETAILER: 'Retailer',
            STOCKIST: 'Wholesaler',
            PRODUCT: 'Product',
            ORDER: 'Order',
            COUPON: 'Coupon',
            PROMOTION: 'Promotion',
            ADMIN: 'Admin',
            BILLING: 'Fee & Coupon',
            GIFT: 'Gift',
            NOTIFICATION: 'Notification',
            CMS: 'CMS',
            REPORT: 'Report',
        },

        RETAILER_TITLE: {
            TITLE_ADD: 'Create new retailer',
            TITLE_UPDATE: 'Retailer details',
        },

        RETAILER: {
            PENDING: 'Pending',
            APPROVE: 'Confirmed',
            REJECT: 'Rejected',
            TITLE_REGISTRANT: 'Registrant information',
            TITLE_STATUS: 'Status',
            STATUS: 'Status*',
            PERSION_IN_CHERT: 'Person in charge name',
            INPUT_PERSION_IN_CHERT: 'Person in charge',
            NAME: 'Name',
            FIRST_NAME: 'First name',
            INPUT_FIRST_NAME: 'Registered name',
            LAST_NAME: 'Last name',
            TAX_ID: 'Tax id',
            MOBILE_PHONE_NUMBER: 'Mobile phone no.',
            SECONDARY_PHONE_NUMBER: 'Store phone no.',
            EMAIL: 'Email',
            TITLE_STORE: 'Store information',
            CUSTOMER_TYPE: 'Customer type',
            STORE_TYPE: 'Store type',
            OPEN_HOURS: 'Opening hours',
            HOUSE: 'Hour',
            MIN: 'Min',
            TO: 'To',
            OPEN_DAY: 'Opening days',
            TITLE_ADDRESS: 'Shipping address',
            POST_CODE: 'Post Code',
            SUB_DISTRICT: 'Subdistrict',
            DISTRICT: 'District',
            PROVINCE: 'Province',
            ADDRESS: 'Address',
            INPUT_ADDRESS: 'Address',
            SEARCH_LOCATION: 'Search location',
            CURRENT_LOCATION: 'Current location',
            TITLE_BILLING_ADDRESS: 'Billing address',
            USE_ADDRESS: 'Use the same address as the shipping address',
            BILLING_POST_CODE: 'Post Code',
            BILLING_SUB_DISTRICT: 'Subdistrict',
            BILLING_DISTRICT: 'District',
            BILLING_PROVINCE: 'Province',
            BILLING_ADDRESS: 'Billing address',
            INPUT_BILLING_ADDRESS: 'Billing address',
            TITLE_DOCUMENT: 'Documents',
            ID_CARD: 'ID card (Front)',
            LIQUOR_LICENSE: 'Liquor license',
            TOBACCO_LICENSE: 'Tobacco license',
            STORE_PICTURE: 'Store picture',
            POR_POR_20: 'P.P.20 (VAT registration certificate)',
            ETC: 'Etc',
            CREATE_DATE: 'Registration date',
            UPDATE_DATE: 'Latest update',
            REJECT_CATEGORY: 'Reject category',
            REJECT_SUBCATEGORY: 'Motivation',
            REJECT_REMARK: 'Remark',
            APPROVE_CATEGORY: 'Approve category',
            APPROVE_REMARK: 'Remark',
            TITLE_REJECT: 'Confirm that the store is not approved',
            DETAIL_REJECT: 'If after refusing Then you want to enable it again. You can do this from the store admin',
            TITLE_APPROVE: 'Confirm store approval',
            DETAIL_APPROVE: 'If after approval Then you want to disable it again. You can do this from the store admin'
        },

        LIST_RETAILER: {
            RETAILER: 'Retailer',
            ADD_RETAILER: 'Add retailer',
            NAME: 'Name',
            ADDRESS: 'Address',
            MOBILEPHONENUMBER: 'Mobile phone number',
            EMAIL: 'Email',
            CREATE_DATE: 'Create date',
            PERSON_CHARGE: 'Person in charge',
        },

        LIST_STOCKIST: {
            STOCKIST: 'Wholesaler',
            ADD_STOCKIST: 'Add Wholesaler',
            NAME: 'Store name',
            ADDRESS: 'Address',
            MOBILEPHONENUMBER: 'Mobile phone number',
            EMAIL: 'Email',
            DELIVERY_ZONE: 'Delivery zone',
            TAX_ID: 'Tax id',
            ACTIVATE: 'Activate',
            DEACTIVATE: 'Inactivate',
            ALL: 'All',
            MODAL_TITTLE: 'Confirm inactivation',
            MODAL_SUBTITTLE: 'Store',
            MODAL_MESSAGE: 'Suppliers will not be able to access the store account once you inactivate',
            MODAL_CONFIRM_LABEL: 'Inactivate',
            MODAL_CANCEL_LABEL: 'Cancel',
            NO_ZONE: 'No zone',
            ZONE: 'Zone'
        },

        RETAILER_STATUS: {
            PENDING: 'Pending',
            CONFIRMED: 'Confirmed',
            REJECTED: 'Rejected',
            ALL: 'All',
        },

        STOCKIST_TITLE: {
            TITLE_ADD: 'Create new Wholesaler',
            TITLE_UPDATE: 'Wholesaler',
        },

        STOCKIST: {
            TITLE_REGISTRANT: 'Account information',
            TITLE_REGIST: 'Registrant information',
            TITLE_STATUS: 'Status',
            STATUS: 'Status*',
            LOGO: 'Store Logo',
            NAME: 'Store name',
            FIRST_NAME: 'First name',
            REGISTERED_NAME: 'Registered name',
            LAST_NAME: 'Last name',
            TAX_ID: 'Tax id',
            MOBILE_PHONE_NUMBER: 'Mobile phone no.',
            EMAIL: 'Email',
            errorTextValidateEmail: 'This email address has already been used',
            BANK_ACCOUNT_NAME: 'Bank account name',
            BANK_ACCOUNT_CODE: 'Bank service provider',
            BANK_ACCOUNT_NO: 'Bank account no.',
            OPEN_DAY: 'Opening days',
            TITLE_ADDRESS: 'Store address',
            POST_CODE: 'Post code',
            SUB_DISTRICT: 'Subdistrict',
            DISTRICT: 'District',
            PROVINCE: 'Province',
            ADDRESS: 'Address',
            INPUT_ADDRESS: 'Input address',
            SEARCH_LOCATION: 'Search location',
            CURRENT_LOCATION: 'Your location',
            TITLE_BILLING_ADDRESS: 'Billing address',
            USE_ADDRESS: 'Use the same address as the store address',
            BILLING_POST_CODE: 'Post code',
            BILLING_SUB_DISTRICT: 'Subdistrict',
            BILLING_DISTRICT: 'District',
            BILLING_PROVINCE: 'Province',
            BILLING_ADDRESS: 'Address',
            INPUT_BILLING_ADDRESS: 'Input billing address',
            TITLE_DOCUMENT: 'Documents',
            ID_CARD: 'ID card (Front)',
            LIQUOR_LICENSE: 'Liquor license',
            TOBACCO_LICENSE: 'Tobacco license',
            STORE_PICTURE: 'Store picture',
            POR_POR_20: 'P.P.20 (VAT registration certificate)',
            ETC: 'Etc',
            CREATE_DATE: 'Registration date',
            UPDATE_DATE: 'Latest updated',
            MODAL_CREATE: {
                TITLE: 'Wholesaler Create Successfully',
                SUBTITLE: '',
                MESSAGE: 'Wholesaler create successfully\ndo you need add additional detail now?',
                CANCEL_BUTTON: 'Back to list',
                CONFIRM: 'Yes'
            }
        },

        RETAILER_HINT: {
            NAME: 'Store name',
            FIRST_NAME: 'First name',
            LAST_NAME: 'Last name',
            TAX_ID: 'Tax id',
            MOBILE_PHONE_NUMBER: 'Mobile phone no.',
            SECONDARY_PHONE_NUMBER: 'Store phone no.',
            EMAIL: 'Email',
            CUSTOMER_TYPE: 'Customer type',
            STORE_TYPE: 'Store type',
            POST_CODE: 'Post code',
            SUB_DISTRICT: 'Subdistrict',
            DISTRICT: 'District',
            PROVINCE: 'Province',
            INPUT_ADDRESS: 'Address',
            BILLING_POST_CODE: 'Post code',
            BILLING_SUB_DISTRICT: 'Subdistrict',
            BILLING_DISTRICT: 'District',
            BILLING_PROVINCE: 'Province',
            INPUT_BILLING_ADDRESS: 'Address',
            HOUSE: 'Hour',
            MIN: 'Min',
            REJECT_CATEGORY: 'Category',
            REJECT_SUBCATEGORY: 'Motivation',
        },

        STOCKIST_HINT: {
            TITLE_REGISTRANT: 'Registrant information',
            STATUS: 'Status',
            NAME: 'Store name',
            FIRST_NAME: 'First name',
            REGISTERED_NAME: 'Registered name',
            LAST_NAME: 'Last name',
            TAX_ID: 'Tax id',
            MOBILE_PHONE_NUMBER: 'Mobile phone no.',
            EMAIL: 'Email',
            POST_CODE: 'Post code',
            SUB_DISTRICT: 'Subdistrict',
            DISTRICT: 'District',
            PROVINCE: 'Province',
            ADDRESS: 'Address',
            BILLING_POST_CODE: 'Post code',
            BILLING_SUB_DISTRICT: 'Subdistrict',
            BILLING_DISTRICT: 'District',
            BILLING_PROVINCE: 'Province',
            BILLING_ADDRESS: 'Address',
            BANK_ACCOUNT_NAME: 'Bank account name',
            BANK_ACCOUNT_CODE: 'Select banking service provider',
            BANK_ACCOUNT_NO: 'Bank account no.',
        },

        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: "Don't have an account?",
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.',
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            },

            LOGOUT: {
                LOGOUT: 'Log out',
                MESSAGE: 'Are you sure to log out?',
                CONFIRM: 'Confirm',
                CANCEL: 'Cancel',
            },
        },

        RETAILER_TAB: {
            STORE_INFORMATION: 'Information',
            STORE_DOCUMENT: 'Documents',
        },

        STOCKIST_TAB: {
            STORE_INFORMATION: 'Information',
            STORE_DOCUMENT: 'Documents',
            STORE_DERIVERY_ZONE: 'Delivery zone',
            STORE_SHELF: 'Shelf',
            STORE_PROMOTION: 'Promotion',
        },

        ACTION: {
            SUBMIT: 'Save',
            CANCEL: 'Cancel',
            PLEASE_SELECT: 'Please select',
            BY: 'By',
            SEARCH: 'Search',
            ADD_FILE: 'Add file',
            APPROVE: 'Approve',
            REJECT: 'Reject',
            DELETE: 'Delete',
            CONFIRM: 'Confirm',
            TO: 'to',
        },

        FORM_MODAL: {
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },

            CANT_CREATE: {
                TITLE: `Can't Create Coupon`,
                SUBTITLE_1: 'Because the same time has already been created.',
                MESSAGE:
                    'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Leave',
            },
        },

        MANUFACTURER: {
            TITTLE_VIEW_MANUFACTURER: 'Manufacturer Detail',
            TITTLE_ADD_MANUFACTURER: 'Add manufacturer',
            TITTLE_EDIT_MANUFACTURER: 'Edit manufacturer',
            DETAIL: 'Detail',
            ID: 'Manufacturer id',
            NAME: 'Manufacturer name (TH)',
            NAME_EN: 'Manufacturer name (EN)',
            LOGO: 'Logo',
            TNTOWNER: 'Manufacturer under Thainamthip'
        },

        MANUFACTURER_TITLE: {
            TITLE_ADD: 'Add manufacturer',
            TITLE_UPDATE: 'Manufacturer detail',
        },
        MANUFACTURER_HINT: {
            ID: 'Manufacturer id',
            NAME: 'Manufacturer name (TH)',
            NAME_EN: 'Manufacturer name (EN)',
        },

        MANUFACTURER_MODAL: {
            CANT_DELETE: {
                TITLE: `Can't Delete Manufacturer`,
                MESSAGE:
                    'There are products in this manufacturer,\nIf you want to delete manufacturer\nPlease remove all products in this manufacturer first.',
                CANCEL: 'Cancel',
            },
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm manufacturer deletion',
                SUBTITLE_1: 'Manufacturer',
                SUBTITLE_2: 'Manufacturer id',
                MESSAGE: 'Do you want to delete this manufacturer ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
        },

        LISTBRAND: {
            BRAND: 'Brand',
            NAME: 'Name',
            BRANDID: 'Brand id',
            MANUFACTURER: 'Manufacturer',
            LOGO: 'Logo',
            ADD_BRAND: 'Add brand',
            EDIT_BRAND: 'Edit brand',
            VIEW_BRAND: 'Brand Detail',
            MODAL_TITTLE: 'Confirm brand inactivation',
            MODAL_MESSAGE: 'Do you want to inactivate this brand?',
            MODAL_CONFIRM: 'Inactivate',
            MODAL_CANCEL: 'Cancel'
        },
        BRAND_STATUS: {
            ACTIVATED: 'Active',
            DISABLE: 'Inactive',
            ALL: 'All',
        },
        BRAND_FORM: {
            DETAIL: 'Detail',
            BRANDID: 'Brand ID',
            ADD_MANUFACTURER: 'Manufacturer',
            NAME: 'Brand name (TH)',
            NAME_EN: 'Brand name (EN)',
            LOGO: 'Logo',
            CANCEL: 'Cancel',
            SAVE: 'Save',
            DELETE: 'Delete'
        },
        BRAND_HINT: {
            DETAIL: 'Detail',
            BRANDID: 'Brand id',
            ADD_MANUFACTURER: 'Manufacturer',
            NAME: 'Brand name (TH)',
            NAME_EN: 'Brand name (EN)',
        },
        BRAND_MODAL: {
            CANT_DELETE: {
                TITLE: `Can't delete brand`,
                MESSAGE:
                    'There are products in this brand,\nIf you want to delete brand\nPlease remove all products in this brand first.',
                CANCEL: 'Cancel',
            },
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm brand deletion',
                SUBTITLE_1: 'Brand',
                SUBTITLE_2: 'Brand id',
                MESSAGE: 'Do you want to delete this brand ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
        },
        LIST_MANUFACTURER: {
            MANUFACTURER: 'Manufacturer',
            ADD_MANUFACTURER: 'Add manufacturer',
            IMAGE: 'Image',
            LOGO: 'Logo',
            MANUFACTURER_ID: 'Manufacturer id',
            NAME: 'Name',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
            MODAL_TITTLE: 'Confirm manufacturer inactivation',
            MODAL_MESSAGE: 'Do you want to inactivate this manufacturer?',
            MODAL_CONFIRM: 'Inactivate',
            MODAL_CANCEL: 'Cancel'

        },

        PRODUCT_FORM: {
            NAME: 'Product name (TH)',
            NAMEEN: 'Product name (EN)',
            EAN: 'EAN',
            BRAND: 'Brand',
            MANUFACTURER: 'Manufacturer',
            DESCRIPTION_PRODUCT: 'Description',
            DESCRIPTION: 'Description (TH)',
            DESCRIPTIONEN: 'Description (EN)',
            CATEGORY: 'Category',
            EDIT_PRODUCT: 'Edit product',
            DETAIL_PRODUCT: 'Product detail',
            COVER_IMAGE: 'Cover image',
            PRODUCT_IMAGE: 'Additional image',
            PRODUCT_IMAGE_COVER: 'Product image',
            CATEGORY_PRODUCT: 'Product category',
            ERROR: {
                NOT_FOUND: "Not found"
            },
            LOADING: "Loading..."
        },
        PRODUCT_HINT: {
            NAME: 'Product name (TH)',
            NAMEEN: 'Product name (EN)',
            EAN: 'EAN',
            BRAND: 'Brand',
            MANUFACTURER: 'Manufacturer',
            DESCRIPTION: 'Description TH (Optional)',
            DESCRIPTIONEN: 'Description EN (Optional)',
            CATEGORY: 'Product category',
        },
        PRODUCT_MODAL: {
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm product deletion',
                SUBTITLE_1: 'Product',
                SUBTITLE_2: 'EAN',
                MESSAGE: 'Do you want to delete this product ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
        },
        ZONE: {
            LABEL_ZONE: 'Delivery zone',
            ADD_ZONE: 'Add delivery zone',
            BACK_BUTTON: 'Back',
            ADD_ZONE_DELIVERY: 'Add delivery zone',
            ZONE_NAME: 'Delivery zone name',
            ZONE_CONDITION: 'Zone condition',
            MININUM_FEE: 'Minimum order amount',
            DELIVERY_FEE: 'Delivery fee',
            ERROR_EMPTY_POLYGON: 'Please draw Delivery Zone.',
            ERROR_POLYGON: 'Delivery Zone error. Please redraw the Delivery Zone.',
        },
        MODAL_DELETE_ZONE: {
            HEADER: "Confirm delivery zone deletion",
            DESCRIPTION: "Do you want delete this delivery zone?",
            CONFRIM: "Delete",
            CANCEL: "Cancel",
        },
        HINT_ZONE: {
            ZONE_NAME: 'Delivery zone name',
            MININUM_FEE: 'Minimum order amount',
            DELIVERY_FEE: 'Delivery fee',
        },

        LIST_PRODUCT: {
            PRODUCT: 'Product',
            ADD_PRODUCT: 'Add product',
            UPLOAD_FILE: 'Upload',
            NAME: 'Name',
            ADDRESS: 'Address',
            MOBILEPHONENUMBER: 'Mobile phone number',
            EMAIL: 'Email',
            DELIVERY_ZONE: 'Delivery zone',
            TAX_ID: 'Tax id',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
            COVERIMAGE: 'Cover image',
            BRANDNAME: 'Brand',
            CATEGORY: 'Category',
            MODAL_TITTLE: 'Confirm the inactivation of the product',
            MODAL_MESSAGE: 'Do you want to inactivate this product?',
            MODAL_CONFIRM: 'Inactive',
            MODAL_CANCEL: 'Cancel'
        },

        LIST_SHELF_MANAGEMENT: {
            PRODUCT: 'Product',
            ADD_PRODUCT: 'Add product',
            NAME: 'Name',
            ADDRESS: 'Address',
            MOBILEPHONENUMBER: 'Mobile phone number',
            EMAIL: 'Email',
            DELIVERY_ZONE: 'Delivery zone',
            TAX_ID: 'Tax id',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
            COVERIMAGE: 'Cover image',
            BRANDNAME: 'Brand',
            CATEGORY: 'Category',
            UNIT_PRICE: 'Price per unit',
            MODAL_TITTLE: 'Confirm the inactivation of the product',
            MODAL_MESSAGE: 'Do you want to inactivate this product ?',
            MODAL_CONFIRM_LABEL: 'Inactive',
            MODAL_CANCEL_LABEL: 'Cancel'
        },

        SHELF_MANAGEMENT: {
            UNIT_PRICE: 'Price per unit',
            MINIMUM_UNIT: 'Minimum',
            MAXIMUM_UNIT: 'Maximum',
            DOWNLOAD_FILE: 'Download file',
            UPLOAD_FILE: 'Upload file',
            UPLOAD_FILE_SUCCESS: 'File uploaded successfully',
            TITLE: 'Edit product details',
            VIEW_TITLE: 'Product details',
            ADD_FILE: 'Add file',
            FILE_DETAIL: 'Please upload file xls,xlsx size not exceeding 100 MB',
            ERROR_FILE_TYPE: 'Incorrect file format, please try again.',
            ERROR_FILE_SIZE: 'File size exceeds limit, please try again.',
            ERROR_UPLOAD: 'Unable to upload file. Please recheck and try again.',
            ERROR_EAN: 'Duplicate EAN occurred. Please recheck and try again.',
        },

        CATEGORY: {
            LEVEL_1: 'Level 1',
            LEVEL_2: 'Level 2',
            LEVEL_3: 'Level 3',
            ADD_CATEGORY: 'Add category',
            CATEGORY_NUMBER: 'Category number',
            CATEGORY_NAME: 'Name',
            CATEGORY_NAME_EN: 'Name (EN)',
            CATEGORY_REQUIRED: 'Please fill out the list',
            NOTFOUND_CATEGORY: 'No data'
        },

        CATEGORY_MODAL: {
            CANT_DELETE: {
                TITLE: `Can't delete category`,
                MESSAGE:
                    'There are products in this Category,\nIf you want to delete category\nPlease remove all products in this category first.',
                CANCEL: 'Cancel',
            },
            CANT_CREATE: {
                TITLE: `Can't create category`,
                MESSAGE:
                    'There are products in this Category,\nIf you want to create sub-category\nPlease remove all products in this category first.',
                MESSAGE_2:
                    'Category name is a duplicate of an existing category.',
                CANCEL: 'Cancel',
            },
            CANT_CREATE_SUB: {
                TITLE: `Can't create category`,
                MESSAGE:
                    'Still have products in this category,\nIf you want to create sub-category\nPlease remove all products in this category first.',
                CANCEL: 'Cancel',
            },
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm category deletion',
                SUBTITLE_1: 'Category',
                SUBTITLE_2: 'Category id',
                MESSAGE: 'Do you want to delete this category ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
        },

        DAY: {
            MON: 'Mon',
            TUE: 'Tue',
            WED: 'Wed',
            THU: 'Thu',
            FRI: 'Fri',
            SAT: 'Sat',
            SUN: 'Sun',
        },

        ADMIN_STATUS: {
            ACTIVATED: 'Active',
            DISABLE: 'Inactive',
            ALL: 'All',
        },

        LIST_ADMIN: {
            ADMIN: 'Admin',
            ADD_ADMIN: 'Add admin',
            ADMIN_ID: 'Admin id',
            NAME: 'Name',
            LAST_NAME: 'Last name',
            ROLE: 'Role',
            EMAIL: 'Email',
            PHONE_NUMBER: 'Mobile Phone no.',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
        },

        ADMIN: {
            TITTLE_ADD_ADMIN: 'Admin',
            TITTLE_EDIT_ADMIN: 'Edit admin detail',
            TITLE_STATUS: 'Status',
            STATUS: 'Status',
            CREATE_DATE: 'Create date',
            UPDATE_DATE: 'Update date',
            DETAIL: 'Detail',
            ID: 'Admin id',
            NAME: 'Name',
            LAST_NAME: 'Last name',
            EMAIL: 'Email',
            MOBILE_PHONE_NUMBER: 'Mobile Phone number',
            TITLE_ROLE: 'Role',
            ROLE: 'Role',
            NAME_EN: 'Name (EN)',
            ACTIVATE: 'Activate',
            INACTIVATE: 'Inactivate',
            BY: 'by',
            errorTextValidateEmail: 'This email address has already been used',
            TITLE_PASSWORD: 'Password',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm password',
        },

        ADMIN_HINT: {
            TITTLE_ADD_ADMIN: 'Add admin',
            TITTLE_EDIT_ADMIN: 'Edit admin detail',
            TITTLE_VIEW_ADMIN: 'View admin detail',
            TITLE_STATUS: 'Status',
            STATUS: 'Status',
            CREATE_DATE: 'Create date',
            UPDATE_DATE: 'Update date',
            DETAIL: 'Detail',
            ID: 'Admin id',
            NAME: 'Name',
            LAST_NAME: 'Last name',
            EMAIL: 'Email',
            MOBILE_PHONE_NUMBER: 'Mobile phone number',
            TITLE_ROLE: 'Role',
            ROLE: 'Role',
            NAME_EN: 'Name (EN)',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm password',
        },

        ADMIN_MODAL: {
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm admin deletion',
                SUBTITLE_1: 'Admin',
                SUBTITLE_2: 'Admin id',
                MESSAGE: 'Do you want to delete this admin ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
            CANT_CREATE: {
                TITLE: "Can't Create Admin",
                MESSAGE: 'Duplicate email from another user',
                CANCEL: 'Cancel',
            },
            CLOSE: {
                TITLE: 'Confirm admin inactivation',
                MESSAGE: 'Do you want to inactivate this Admin ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Inactivate',
            },
        },
        LIST_COUPON: {
            TITLE: 'Coupon',
            ADD_COUPON: 'Add coupon',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
            CODE: 'Coupon code',
            DISCOUNT: 'Discount',
            TOTAL_DISCOUNT: 'Total discount',
            QUOTA_USED: 'Redemption count',
            EFFECTIVE_DATE: 'Validity period',
            STATUS: 'Status',
            STATUS_INACTIVE: 'Inactive',
            STATUS_EXPIRE: 'Expire',
            STATUS_ACTIVE: 'Active',
            STATUS_UP_COMING: 'Upcoming',
            TITLE_ERR_ACTIVE: "Can't activate coupon",
            MESSAGE_ERR_ACTIVE: 'Because the same coupon has already been created.',
            BTN_CANCEL: 'Cancel'

        },
        COUPON: {
            VIEW: {
                TITLE: 'View Coupon',
            },
            CREATE: {
                TITLE: 'Create coupon',
                EDIT_TITLE: 'Edit coupon',
                TITLE_DETAIL_COUPON: 'Coupon details',
                TITLE_DETAIL_REDUCE_RATE: 'Deduction type',
                COUPON_CODE: 'Coupon code',
                HINT_COUPON_CODE: 'Coupon code',
                MINIMUM_PRICE: 'Minimum order(Baht)',
                HINT_MINIMUM_PRICE: 'Minimum order(Baht)',
                PERCENT: 'Percent',
                CURRENCY: 'Baht(unit)',
                DISSCOUNT: 'Discount',
                HINT_DISSCOUNT: 'Discount',
                HINT_DISSCOUNT_PERCENT: '%',
                LIMIT_DISSCOUNT_PERCENT: 'Maximum discount',
                HINT_LIMIT_DISSCOUNT_PERCENT: '฿',
                HINT_DISSCOUNT_CURENCY: '฿',
                TITLE_CONDITION: 'Usage condition',
                START_DATE: 'Activation period',
                END_DATE: 'to',
                QOUTAPERRETAILER: 'Limit per retailer',
                HINT_QOUTAPERRETAILER: 'Limit per retailer',
                QOUTALIMIT: 'Total coupon limit',
                HINT_QOUTALIMIT: 'Total coupon limit',
                TITLE_RETAILERTYPE: 'Set participating retailers',
                TITLE_PRODUCTTYPE: 'Set participating products',
                ALLTYPE: 'All',
                EXCEPTTYPERETAILER: 'Except these retailers',
                SPECIFICTYPERETAILER: 'Only these retailers',
                EXCEPTTYPEPRODUCT: 'Except these products',
                SPECIFICTYPEPRODUCT: 'Only these products',
                HINT_TIME: '00',
                HINT_DATE: 'YYYY-MM-DD',
                DOWNLOAD_FILE_EXAMPLE: 'Download sample file',
                DOWNLOAD_FILE: 'Download file',
                ADD_FILE: 'Add file',
                HINT_ADD_FILE:
                    'Please upload a csv file, size not exceeding 100 Mb.',
                ERROR_FILE_TYPE: 'Incorrect file format, please try again.',
                ERROR_FILE_SIZE:
                    'File size exceeds limit, please try again',
            },
            MODAL_DISABLE: {
                MODAL_TITTLE: "Confirm coupon inactivation",
                MODAL_MESSAGE: "Do you want to inactivate this coupon?",
                MODAL_CONFIRM: "Inactivate",
                MODAL_CANCEL: "Cancel",
            },
            DELETE: {
                TITLE: 'Confirm coupon deletion',
                MESSAGE: 'Do you want to delete this coupon?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            }
        },
        ORDER_LIST: {
            ORDER_ID: 'Order ID',
            STOCKIST_NAME: 'Wholesaler',
            RETAILER_NAME: 'Retailer',
            UPDATE_AT: 'Last update',
            CREATE_AT: 'Order Date',
            NET_AMOUNT: 'Net amount',
            COUPON_DISCOUNT: 'Coupon discount',
            TOTAL_AMOUNT: 'Order amount',
            STATUS: 'Status'
        },
        ORDER_STATUS: {
            ALL: 'All',
            PENDING: 'Pending',
            CONFIRMED: 'Confirmed',
            CANCELREQUESTED: 'Cancel request',
            CANCELLED: 'Cancelled',
            DEPRECATED: 'Edited Order',
            CANCELLED_RETAILER: 'Cancelled by retailer',
            CANCELLED_STOCKIST: 'Cancelled by Wholesaler',
            DELIVERED: 'Delivered'
        },
        ORDER_DETAIL: {
            TITLE: 'Fee',
            DATE_SUCCESS: 'Delivered on',
            RETAILER_NAME: 'Retailer',
            ORDER: 'Order',
            DETAIL: 'Order detail',
            ORDER_DATE: 'Order date',
            STOCKIST_NAMES: 'Wholesaler',
            EXPECTED_DATE: 'Expect date of delivery',
            STATUS: 'Status',
            LAST_UPDATE: 'Last update',
            ORDER_LIST: 'Order list',
            PRICES_ORDER_ALL: 'Merchandise value',
            PIECE: 'Item',
            DISCOUNT: 'Coupon discount',
            PROMOTION_DISCOUNT: 'Promotion discount',
            SHIPPING: 'Delivery fee',
            BILLING: '1% Fee',
            TOTAL_COUPON: 'Coupon',
            ORDER_FAIL: 'Undelivered item amount',
            GET_COUPON_BACK: 'Coupon redemption',
            ORIGINAL_ORDER: 'View order before changes',
            NEW_ORDER: 'View new order',
            CHANGED_ORDER: 'There is a change to an order',
            TOTAL_NET_AMOUNT: 'Net amount',
            LIST_PRODUCT_NAME: 'Product',
            LIST_PRODUCT_CATEGORY: 'Category',
            LIST_PRODUCT_PRICE_PER_UNIT: 'Price per unit',
            LIST_PRODUCT_QTY: 'Amount',
            LIST_SUM_AMOUNT: 'Total price',
            LIST_PRODUCT_PHOTO: 'Image'
        },
        LIST_GIFT: {
            TITLE: 'Gift',
            ADD_GIFT: 'Add gift',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
            STATUS: 'Status',
            DATA_TABLE: {
                COVER_IMAGE_PATH: 'Image',
                EAN: 'EAN',
                CODE: 'EAN',
                NAME: 'Name',
                CATEGORY: 'Category'
            },
            CREATE: {
                TITLE: 'Create gift',
                DETAIL_GIFT: 'Gift detail',
                LABEL_NAME: 'Gift name (TH)',
                LABEL_NAMEEN: 'Gift name (EN)',
                LABEL_EAN: 'EAN',
                LABEL_LOGO: 'Cover image',
                PLACEHOLDER_NAME: 'Gift name (TH)',
                PLACEHOLDER_NAMEEN: 'Gift name (EN)',
                PLACEHOLDER_EAN: 'Enter EAN',
                DESC_GIFT: 'Description',
                LABEL_DESC: 'Description (TH)',
                PLACEHOLDER_DESC: 'Description TH (Optional)',
                LABEL_DESCEN: 'Description (EN)',
                PLACEHOLDER_DESCEN: 'Description EN (Optional)',
                CATEGORY_GIFT: 'Category',
                CATEGORY_1: 'Level 1',
                CATEGORY_2: 'Level 2',
                CATEGORY_3: 'Level 3',
                LABEL_SEARCH: 'Search'
            },
            EDIT: {
                TITLE: 'Edit gift',
            },
            ERROR: {
                NOT_FOUND: "Not found",
                LOADING: "Loading..."
            },
            VIEW: {
                TITLE: 'Gift detail',
            }
        },
        GIFT_MODAL: {
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm gift deletion',
                SUBTITLE_1: 'Gift',
                SUBTITLE_2: 'EAN',
                MESSAGE: 'Do you want to delete this gift ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
            CLOSE: {
                TITLE: 'Confirm gift inactivation',
                MESSAGE: 'Do you want to inactivate this gift?',
                CONFIRM: 'Inactivate',
                CANCEL: 'Cancel',
            }
        },
        LIST_CMS: {
            TITLE: 'Content management system'
        },
        LIST_BANNER: {
            TITLE: 'Banner',
            IMAGE: 'Banner image',
            NAME: 'Banner name',
            ACTIVE_PERIOD: 'Validity period',
            STATUS: 'Status',
            CREATE: {
                TITLE: 'Add banner'
            },
            PERMISSION_DENINED: {
                HEADER: "Unable to make a change",
                DETAIL: "you cannot edit or any change due to permission restriction",
                CLOSE: "cloase"
            }

        },
        AVAILABLE_STATUS: {
            VALID: 'Valid',
            INVALID: 'Invalid',
            SCHEDULE: 'Schedule'
        },
        LIST_SHOWCASES: {
            TITLE: 'Showcase',
            NAME: 'Showcase name',
            ACTIVE_PERIOD: 'Validity period',
            STATUS: 'Status',
            CREATE: {
                TITLE: 'Add showcase'
            }
        },
        LIST_PRODUCT_HISTORY: {
            TITLE: 'Product upload history',
            UPLOAD_AT: 'Upload on',
            UPLOAD_AMOUNT: 'Upload amount',
            SUCCESS_UPLOAD_AMOUNT: 'Success uploaded amount',
            IMAGE_FILE_NAME: 'Image file name',
            DATA_FILE_NAME: 'Data file name',
            CREATE: {
                TITLE_IMAGE: 'Image file',
                TITLE_DATA: 'Data file',
                DOWNLOAD_FILE_EXAMPLE: 'Download sample file',
                DOWNLOAD_FILE: 'Download File',
                ADD_FILE: 'Add file',
                HINT_ADD_IMAGE_FILE: 'Please upload a zip file that contain jpg, size not exceeding 100 Mb.',
                HINT_ADD_FILE:
                    'Please upload a csv file, size not exceeding 100 Mb.',
                ERROR_FILE_TYPE: 'Incorrect file format Please try again.',
                ERROR_FILE_SIZE:
                    'File size exceeds limit, Please try again.',
            },
            DETAIL: {
                ERROR_TITLE: 'Error type',
                EAN: 'EAN'
            }
        },
        LIST_BILLING: {
            TITLE: 'Fee & Coupon',
            TOTAL_ORDER: 'Total order',
            TOTAL_AMOUNT: 'Total amount',
            TOTAL_ORDER_1: 'Total order',
            TOTAL_AMOUNT_1: 'Total amount',
            TOTAL_BILLING: 'Total fee',
            TOTAL_BILLING_1: 'Total fee',
            COUPON: 'Total coupon redemption',
            COUPONS: 'Coupon redemption',
            STOCKIST_NAME: 'Wholesaler',
            AMOUNT: 'Amount',
            SERVICE: 'Service',
            TOTAL: 'Total',
            FEE: 'Fee',
            TOTAL_GMV: 'Total merchandise value',
            TOTAL_NMV: 'Net amount',
            DATE_SUCCESS: 'Delivered on',
            RETAILER_NAME: 'Retailer',
            ORDER: 'Order',
            ORDER_PHOTO: 'Image',
            ORDER_NAME: 'Product',
            GIFT_PHOTO: 'Image',
            GIFT_NAME: 'Gift',
            CATEGORY: 'Category',
            PRICE_PER_UNIT: 'Price per unit',
            AMOUNT_DETAIL: 'Quantity',
            TOTAL_PRICE: 'Total price',
            DETAIL: 'Order detail',
            ORDER_DATE: 'Order date',
            STOCKIST_NAMES: 'Wholesaler',
            EXPECTED_DATE: 'Expect date of delivery',
            STATUS: 'Status',
            LAST_UPDATE: 'Last update',
            ORDER_LIST: 'Order list',
            GIFT_LIST: 'Gift list',
            PRICES_ORDER_ALL: 'Merchandise value',
            PIECE: 'Item',
            DISCOUNT: 'Coupon discount',
            SHIPPING: 'Delivery fee',
            BILLING: '1% Fee',
            TOTAL_COUPON: 'Coupon',
            PRICE_ORDER_FAIL: 'Undelivered item amount',
            GET_COUPON_BACK: 'Coupon redemption',
            ORIGINAL_ORDER: 'View order before changes',
            CHANGED_ORDER: 'There is a change to an order',
            GO_BACK: 'View original order',
            TOTAL_NET: 'Net Amount',
            ORDER_ID: 'Order id',
            PROMOTION_DISCOUNT: 'Promotion discount',
            AMOUNT_PRODUCT: 'Amount',
            ADDITIONAL_DISCOUNT: 'Additional discount',
            STOCKIST_DETAIL: {
                TITLE: 'Fee & Coupon of',
            },
            SUMMARY: 'Summary',
        },
        PROMOTION: {
            TITLE: 'Add promotion',
            TITLE_EDIT: 'Edit promotion',
            TITLE_DETAIL: 'Promotion detail',
            TITLE_TYPE: 'Promotion type',
            NAME: 'Promotion name',
            DATAIL: 'Promotion detail',
            TIME: 'Promotion time',
            TO: 'to',
            TYPE: 'Promotion type',
            TYPE_1: 'Discount promotion',
            TYPE_2: 'Gift promotion',
            CONDITION: 'Promotion condition',
            CONDITION_1: 'Tier',
            CONDITION_2: 'Bundle',
            CONDITION_2_DETAIL: 'When purchasing every',
            GIFT_1: 'Receive',
            GIFT_2: 'gift from this list.',
            STEP: 'Tier',
            BUY_PRODUCT: 'When purchasing',
            AMOUNT_TYPE: 'to',
            AMOUNT_TYPE_1: 'Limit',
            AMOUNT_TYPE_2: 'Unlimit',
            DISCOUNT_TYPE: 'Discount per unit',
            DISCOUNT_TYPE_1: '%',
            DISCOUNT_TYPE_2: '฿',
            DISCOUNT_TYPE_3: 'Discount per unit',
            ADD_CONDITION: 'Add tier',
            PRODUCT: 'Participating product',
            ADD_PRODUCT: 'Add product',
            ADD_GIFT: 'Add gift',
            ALL_PRODUCT_TYPE: 'สินค้าทั้งหมดที่ขาย',
            ALL_PRODUCT_TYPE_1: 'Unlimit',
            ALL_PRODUCT_TYPE_2: 'Limit',
            ALL_STOCKIST_TYPE: 'Number of stores where purchases can be made',
            ALL_STOCKIST_TYPE_1: 'Unlimit',
            ALL_STOCKIST_TYPE_2: 'Limit',
            FULL_UNIT: 'When purchasing every',
            UNIT: 'Unit',
            PLACEHOLDER: 'Input amount',
            PRODUCT_TITLE: 'Products',
            ADDITIONAL_PRIVILEGE: 'Additional Privilege',
            ADDITIONAL_PRIVILEGE_CONDITION: 'Condition: When you buy products that match the promotion rules above.',
            EXTRA_PRIVILEGE: 'get extra benefits as',
            DISCOUNT: 'Discount',
            GIFT: 'Gift',
            GIFT_INDEX: 'Gift number',
            ADD_MORE_GIFT: 'Add more gift',
            GIFT_NAME: 'Gift name',
            ADD_MULTI_GIFT: 'Add more gift',
            UNIT_TYPE: 'Unit',
            CURRENCY_TYPE: 'Baht',
            TIER_CONDITION: 'Tier condition',
            PURCHASE_CONDITION: 'Purchase condition',
            PURCHASE_CONDITION_UNIT: 'Unit',
            PURCHASE_CONDITION_CURRENCY: 'Currency',
            LIMIT_TYPE: 'Limit condition',
            PRIVILEGE: 'Privilege',
            PRIVILEGE_TYPE1: 'Discount',
            PRIVILEGE_TYPE2: 'Gift',
            BUNDLE_GROUP: {
                INFORMATION: 'Select products with the same price in each product set.',
                PRODUCT_SET: 'Product set',
                ADD_PRODUCT_SET: 'Add product set',
                ERROR: {
                    PRODUCT_EMPTY: 'Please select a product.',
                    INVALID_PRODUCT_PRICE: 'Select products with the same price in each product set.',
                    GIFT_EMPTY: 'Please select a gift.'
                }
            },
            TIER: 'Tier',
            FROM_AMOUNT: 'From',
            FROM_AMOUNT_MULTI_TIER: 'From more than',
            TO_AMOUNT: 'To',
            ADD_TIER: 'Add more tier condition',
            BUNDLE_PRIVILEGE_TITLE: 'Privilege',
            COVER_IMAGE: 'Cover image',
            COVER_IMAGE_ERROR: 'Please input image',
            ADDITIONAL_WANNING: 'optional',
            CONDITION_WANNING: 'any product in the list'
        },

        PROMOTION_HINT: {
            NAME: 'Input Promotion Name',
            DATAIL: 'Enter Promotion Detail',
            TIME: 'ช่วงเวลาโปรโมชั่น',
            HINT_TIME: '00',
            TYPE: 'Promotion Type',
            TYPE_1: 'ซื้อครบลดเลย',
            TYPE_2: 'ซื้อสินค้ารับของแถม',
            CONDITION: 'เงื่อนไขโปรโมชั่น',
            CONDITION_1: 'ขั้นบันได',
            CONDITION_2: 'ชุดสินค้า',
            STEP: 'ขั้น',
            BUY_PRODUCT: 'เมื่อซื้อสินค้า',
            AMOUNT_TYPE: 'จนถึงจำนวน',
            AMOUNT_TYPE_1: 'สูงสุดไม่เกิน',
            AMOUNT_TYPE_2: 'ไม่จำกัด',
            DISCOUNT_TYPE: 'จะได้รับส่วนลด',
            DISCOUNT_TYPE_1: '%',
            DISCOUNT_TYPE_2: '฿',
            ADD_CONDITION: 'เพิ่มเงื่อนไข',
            PRODUCT: 'สินค้าที่เข้าร่วม',
            ADD_PRODUCT: 'เพิ่มสินค้า',
            ALL_PRODUCT_TYPE: 'สินค้าทั้งหมดที่ขาย',
            ALL_PRODUCT_TYPE_1: 'ไม่จำกัด',
            ALL_PRODUCT_TYPE_2: 'จำกัด',
            ALL_STOCKIST_TYPE: 'จำนวนร้านค้าที่ซื้อได้',
            ALL_STOCKIST_TYPE_1: 'ไม่จำกัด',
            ALL_STOCKIST_TYPE_2: 'จำกัด',
            EVERY: 'Input Amount',
            QUANITY: 'Input Amount',
            TIER_BUTTON: 'Tier',
            BUNDLE_BUTTON: 'Bundle',
        },

        PROMOTION_TAB: {
            STOCKIST_PRODUCT: 'Product',
            GIFT: 'Gift'
        },

        LIST_PROMOTION: {
            PROMOTION: 'Promotion',
            ADD_PROMOTION: 'Add promotion',
            NAME: 'Promotion name',
            PROMOTION_TYPE: 'Promotion type',
            CONDITION_PROMOTION: 'Promotion condition',
            PERIOD_PROMOTION: 'Promotion period',
            STATUS: 'Status',
            PROMOTION_GIFT: 'Giveaway',
            PROMOTION_DISCOUNT: 'Discount',
            CONDITION_TIERED: 'Tiered',
            CONDITION_BUNDLE: 'Bundle',
            STATUS_ACTIVE: 'Active',
            STATUS_EXPIRED: 'Expired',
            STATUS_WAITING: 'Upcoming',
            STATUS_INACTIVE: 'Inactive',
            TITLE_ERR_ACTIVE: "Can't activate promotion",
            MESSAGE_ERR_ACTIVE: 'Because the same promotion has already been created.',
            BTN_CANCEL: 'Cancel',
            MODAL_TITTLE: 'Confirm the inactivation of the promotion',
            MODAL_MESSAGE: 'Do you want to inactivate this promotion ?',
            MODAL_CONFIRM_LABEL: 'Inactive',
            MODAL_CANCEL_LABEL: 'Cancel',
            PROMOTION_CONDITION: 'Condition',
            PRIVILEGE_CONDITION: 'Privilege'
        },
        PROMOTION_STATUS: {
            ALL: 'All',
            ACTIVE: 'Active',
            INACTIVE: 'Inactive',
        },

        PROMOTION_MODAL: {
            CANT_DELETE: {
                TITLE: `Can't delete promotion`,
                MESSAGE:
                    '',
                CANCEL: 'Cancel',
            },
            CANT_CREATE: {
                TITLE: `Can't create promotion`,
                SUBTITLE_1: 'Because the same promotion has already been created.',
                MESSAGE:
                    'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Leave',
            },

            CANT_EDIT: {
                TITLE: `Can't edit promotion`,
                SUBTITLE_1: 'Because the same promotion has already been created.',
                MESSAGE:
                    'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Leave',
            },

            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            DELETE: {
                TITLE: 'Confirm promotion deletion',
                SUBTITLE_1: 'Promotion',
                SUBTITLE_2: 'Promotion id',
                MESSAGE: 'Do you want to delete this Promotion ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
        },

        NOTIFICATION: {
            TITTLE_CREATE: 'Create notification',
            TITTLE_EDIT: 'Edit notification',
            TITTLE_VIEW: 'View notification',
            TITTLE_HISTORY: 'Notification history',
            SETTING_NOTIFICATION: 'Notification settings',
            DETAIL: 'Detail',
            SUBJECT: 'Title (TH)',
            SUBJECT_EN: 'Title (EN)',
            HINT_SUBJECT: 'Title',
            SHORT_MSG: 'Short Message (Thai)',
            SHORT_MSG_EN: 'Short Message (Eng)',
            HINT_SHORT_MSG: 'Message Short Thai Message',
            HINT_SHORT_MSG_EN: 'Message Short Eng Message',
            FULL_MSG: 'Full Message (Thai)',
            FULL_MSG_EN: 'Full Message (Eng)',
            HINT_FULL_MSG_EN: 'Enter Full Message Eng',
            HINT_FULL_MSG: 'Enter Full Message Thai',
            RADIO_IMMEDIATELY: 'Immediate',
            RADIO_SCHEDULE: 'Schedule',
            TYPE: 'Notification Type',
            HINT_NOTIFICATION: 'Notification type',
            ATTACHMENT_PHOTO: 'Attach image',
            SAVE: 'Save',
            CANCEL: 'Cancel',
            NEWS: 'News',
            COUPON: 'Coupon',
            PROMOTION: 'Promotion',
            RECEIVER_TYPE_TITTLE: 'Permission Receiver',
            RECEIVER_TYPE_ALL: 'All',
            RECEIVER_TYPE_EXPECT: 'Except these retailers',
            RECEIVER_TYPE_SPECIFIC: 'Only these retailers',
            CANCEL_NOTIFICATION: 'Cancel notification',
            CREATE: {
                TITLE_IMAGE: 'Image file',
                TITLE_DATA: 'Data file',
                DOWNLOAD_FILE_EXAMPLE: 'Download sample file',
                DOWNLOAD_FILE: 'Download File',
                ADD_FILE: 'Add File',
                HINT_ADD_IMAGE_FILE: 'Please upload a zip file that contain jpg, size not exceeding 100 Mb.',
                HINT_ADD_FILE:
                    'Please upload a csv file, size not exceeding 100 Mb.',
                ERROR_FILE_TYPE: 'Incorrect file format Please try again.',
                ERROR_FILE_SIZE:
                    'File size exceeds limit Please try again. Please try again.',
            },
            EDIT: {
                WARNING_SUCCESS: 'Notification sent successfully',
                WARNING_CANCEL: 'Notification cancelled',
            }
        },
        LIST_NOTIFICATION: {
            TITLE: 'Notification',
            ADD_NOTIFICATION: 'Add Notification',
            STATUS: {
                PENDING: 'Pending',
                SUCCESS: 'Success',
                CANCEL: 'Cancel',
                ALL: 'All'
            },
            DATA_TABLE: {
                COL1: 'Notification topic',
                COL2: 'Message preview',
                COL3: 'Type',
                COL4: 'Effective date',
                COL5: 'Status'
            },
            TYPE: {
                NEWS: 'News',
                PROMOTION: 'Promotion',
                COUPON: 'Coupon',
                ORDER: 'Order'
            }
        },
        NOTIFICATION_MODAL: {
            CANCEL: {
                TITLE: 'Data will not be saved',
                MESSAGE: 'Do you want to leave this page?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
            CANCEL_NOTIFICATION: {
                TITLE: 'Cancellation confirmation',
                MESSAGE: 'This notification will be cancelled\nDo you want to proceed?',
                CANCEL: 'Cancel',
                CONFIRM: 'Confirm',
            },
        },
        CMS: {
            LIST_SHOWCASE: {
                MODAL_TITTLE: "Confirm showcase inactivation",
                MODAL_MESSAGE: "Do you want to inactivate this showcase?",
                MODAL_CONFIRM: "Inactivate",
                MODAL_CANCEL: "Cancel",
            },
            LIST_BANNER: {
                MODAL_TITTLE: "Confirm banner inactivation",
                MODAL_MESSAGE: "Do you want to deactivate this banner?",
                MODAL_CONFIRM: "Inactivate",
                MODAL_CANCEL: "Cancel",
            },
            CREATE: {
                TITLE: "Add showcase",
                TITLE_EDIT: 'Edit showcase',
                SUBTITLE_NAME: "Showcase name",
                LABEL_NAME: "Name (TH)",
                LABEL_NAMEEN: "Name (EN)",
                PLACEHOLDER_NAME: "Enter topic",
                PLACEHOLDER_DISPLAY: "Sort option",
                LABEL_DISPLAY: 'Sort by',
                LABEL_TIME: "Time",
                DISPLAY: "Display",
                PROMOTION: "Promotion",
                PRODUCT: "Product",
                BRAND: "Brand",
                CATEGORIES: "Categories",
                SORT_OPTION: {
                    PRICE_LOW_TO_HIGH: "For lower unit selling price",
                    PRICE_HIGH_TO_LOW: "For higher unit selling price",
                    LAST_UPDATE: "By date of last update",
                    AMOUNT_TOTAL_PURCHASES: "By amount of total purchases",
                    AMOUNT_TOTAL_SALES: "By amount of total sales",
                    AMOUNT_TOTAL_SALES_DAY: "By amount of sales in the last 15 day",
                },
                ERROR_DISPLAY: 'Please select at least 1 configuration.'
            }
        },
        FORM_BANNER: {
            TITLE: 'Banner',
            TITLE_NAME: 'Banner name',
            TITLE_CREATE: 'Add banner',
            TITLE_EDIT: 'Edit banner',
            NAME_TH: 'Name* (TH)',
            NAME_TH_PLACEHOLDER: 'Enter banner topic',
            NAME_EN: 'Name* (EN)',
            NAME_EN_PLACEHOLDER: 'Enter banner topic',
            TITLE_TIME: 'Validity period',
            TIME_TO: 'to',
            TITLE_IMAGE: 'Banner image',
            TITLE_PERMISSION_RETAILER: 'Retailer Permission',
            PERMISSION_RETAILER: {
                ALL: 'All',
                EXCEPT: 'Except',
                SPECIFIC: 'Specific'
            },
            FILE: {
                ADD_FILE: 'Add File',
                PLACEHOLDER_FILE: 'Please upload a csv file, size not exceeding 100 Mb.',
                PLACEHOLDER_IMAGE: 'Please upload a jpg or png file, size not exceeding 100Mb.',
                DOWNLOAD_EXAMPLE: 'Download sample file',
                DOWNLOAD_FILE: 'Download',
                ERROR: {
                    ERROR_FILE_TYPE: 'Incorrect file format Please try again.',
                    ERROR_FILE_SIZE:
                        'File size exceeds limit Please try again. Please try again.',
                    ERROR_FILE_REQUIRED: 'Please upload file.'
                }
            },
            TITLE_SHOWCASE: 'Attach showcase',
            SHOWCASE_CONDITION: {
                NO: 'None',
                SHOWCASE: 'Showcase',
                PROMOTION: 'Set product'
            },
            SHOWCASE: {
                TITLE_SELECT_SHOWCASE: 'Select showcase',
                PLACEHOLDER: 'Showcase',
                ERROR: {
                    NOT_SELECT: 'Please select showcase'
                }
            },
            PROMOTION: {
                DISCOUNT: 'Discount',
                GIFT: 'Gift',
                TITLE_PRODUCT: 'Product',
                STOCKIST: 'Wholesaler',
                STOCKIST_PLACEHOLDER: 'Wholesaler',
                BRAND: 'Brand',
                BRAND_PLACEHOLDER: 'Brand',
                SELECTED_PLACEHOLDER: '{{count}} selected',
                CATEGORY: 'Category',
                CATEGORY_PLACEHOLDER: 'Category',
                PROMOTION: 'Promotion',
                ERROR: {
                    NOT_SELECT: 'Please select at least 1 configuration.'
                }
            },
            DELETE: {
                TITLE: 'Confirm banner deletion',
                MESSAGE: 'Are you sure about delete banner ?',
                CANCEL: 'Cancel',
                CONFIRM: 'Delete',
            },
            ERROR: {
                TITLE: 'Unexpected error',
                MESSAGE: 'An error occurs, please try again.',
                CANCEL: 'Close',
            }
        },
        UPLOAD_PRODUCT_HISTORY_DETAIL: {
            TITLE: 'Upload Result',
            STATUS: {
                TITLE: 'Status',
                UPLOAD_AT: 'Upload at',
                UPLOAD_BY: 'Upload by',
                UPLOAD_AMOUNT: 'Upload amount',
                SUCCESS_UPLOAD_AMOUNT: 'Upload success amount',
                IMAGE_FILE_NAME: 'Image file',
                IMAGE_FILE_DATA: 'Data file'
            },
            LIST_UNSUCCESS_UPLOAD: {
                TITLE: 'Unsuccess upload product',
                ERROR_TYPE: {
                    BRAND: 'Incorrect brand',
                    CATEGORY: 'Incorrect category',
                    EAN: 'Duplicate EAN',
                    IMAGE: 'Incorrect image',
                    LENGTH: 'Please recheck your EAN to be at least\n13 characters and maximum at 20 characters',
                    ERROR_TITLE: 'Unable to upload file',
                }
            }
        },
        PERMISSION_MODAL: {
            PERMISSION: {
                TITLE: 'Unable to make a change',
                MESSAGE: 'You cannot edit or make any changes due to permission restriction.',
                CONFIRM: 'Close',
            }
        },
        NOT_FOUND: {
            MESSAGE: 'No data'
        },
        REPORT: {
            TITLE: 'Report',
            SUBTITLE: 'Details',
            CUSTOMER_REPORT: 'Customer Report',
            DOWNLOAD: 'Download',
            ORDER_REPORT: 'Order Report',
            ORDER_ITEM: 'Order Products Report',
            ACTIVE_PRODUCT: 'Active Products Report',
        }
    },
};
