import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    RejectCategory,
    SubRejectectCategory,
} from "@core/models/reject-category/category.model";
import { ClearParams } from "@modules/shared/clear-params";
import { Observable, from } from "rxjs";
import { StoreType } from "../../models/retailer/customer-type.model";
import {
    Retailer,
    RetailerDatatable,
    RetailerParams,
    uploadRetailer,
} from "../../models/retailer/retailer.model";
import { ApproveCategory } from "@core/models/approve-category/category.model";
import axios from "axios";
import { ConfigService } from "../shared/config.service";

@Injectable({
    providedIn: "root",
})
export class RetailerService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    getList(params: RetailerParams): Observable<RetailerDatatable> {
        return this.http.get<RetailerDatatable>(
            this.config.getApiUrl("/customer/retailers"),
            {
                params: ClearParams(params),
            },
        );
    }

    get(id: number): Observable<Retailer> {
        return this.http.get<Retailer>(
            this.config.getApiUrl(`/customer/retailers/${id}`),
        );
    }

    create(params: Retailer): Observable<Retailer> {
        return this.http.post<Retailer>(
            this.config.getApiUrl("/customer/retailers"),
            params,
        );
    }

    update(id: number, params = {}): Observable<Retailer> {
        return this.http.patch<Retailer>(
            this.config.getApiUrl("/customer/retailers"),
            {
                id: id,
                ...params,
            },
        );
    }

    updateDocument(id: number, params = {}): Observable<Retailer> {
        return this.http.patch<Retailer>(
            this.config.getApiUrl(`/customer/retailers/${id}/documents`),
            params,
        );
    }

    getStoreType(filterCode: string): Observable<StoreType> {
        return this.http.get<StoreType>(
            this.config.getApiUrl(`/customer/store-type/${filterCode}`),
        );
    }

    getRejectCategory(): Observable<RejectCategory[]> {
        return this.http.get<RejectCategory[]>(
            this.config.getApiUrl(`/customer/RejectCategories`),
        );
    }

    getSubRejectCategory(
        categoryId: number,
    ): Observable<SubRejectectCategory[]> {
        if (categoryId != null) {
            return this.http.get<SubRejectectCategory[]>(
                this.config.getApiUrl(
                    `/customer/RejectCategories/${categoryId}`,
                ),
            );
        }
        return this.http.get<SubRejectectCategory[]>(
            this.config.getApiUrl("/customer/RejectCategories"),
        );
    }

    getApproveCategory(): Observable<ApproveCategory[]> {
        return this.http.get<ApproveCategory[]>(
            this.config.getApiUrl("/customer/ApproveCategories"),
        );
    }

    validateModilePhoneNumber(phoneNumber: string): Observable<boolean> {
        return this.http.get<boolean>(
            this.config.getApiUrl(`/customer/duplicate-phone`),
            {
                params: { phoneNumber: phoneNumber },
            },
        );
    }

    uploadDocument(value: FormData): Observable<uploadRetailer> {
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
        };

        return from(
            axios.post(
                this.config.getApiUrl("/customer/retailers/upload"),
                value,
                {
                    headers,
                },
            ),
        );
    }
}
