import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { group } from '@core/models/group.model';
import { UserService } from '@core/services/auth/user.service';
import { ConfigService } from '@core/services/shared/config.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Permission } from '@utils/permission.enum';
import { filter } from 'rxjs/operators';
import { VersionToggleService } from 'version-toggle';

interface MenuObject {
    id: number;
    name: string;
    icon: string;
    path: string;
    mainPath?: string | undefined;
    permission_key?: string[];
}

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
    currentUrl = '';
    menu: MenuObject[] = [];
    currentEnv = ''
    activeMenu: string | null = null;
    isActiveMenu = false;
    isVersion2 = false

    constructor(
        private router: Router,
        private userService: UserService,
        private translate: TranslateService,
        private config: ConfigService,
        private versionToggleSerice: VersionToggleService
    ) {
        this.initMenu();
    }

    

    initMenu() {
        let product = '';
        let order = '';
        let coupon = '';
        let retailer = '';
        let stockist = '';
        let admin = '';
        let billing = '';
        let gift = '';
        let notification = '';
        let cms = '';
        let report = '';

        // console.log(this.versionToggleSerice.getAppVersion());

        if (this.translate) {
            product = this.translate.instant('SIDE_BAR.PRODUCT');
            order = this.translate.instant('SIDE_BAR.ORDER');
            coupon = this.translate.instant('SIDE_BAR.COUPON');
            retailer = this.translate.instant('SIDE_BAR.RETAILER');
            stockist = this.translate.instant('SIDE_BAR.STOCKIST');
            admin = this.translate.instant('SIDE_BAR.ADMIN');
            billing = this.translate.instant('SIDE_BAR.BILLING');
            gift = this.translate.instant('SIDE_BAR.GIFT');
            notification = this.translate.instant('SIDE_BAR.NOTIFICATION');
            cms = this.translate.instant('SIDE_BAR.CMS');
            report = this.translate.instant('SIDE_BAR.REPORT');
        }

        const mockMenu: MenuObject[] = [];
        mockMenu.push(
            {
                id: 0,
                name: retailer,
                icon: 'assets/icons/sidebar-icon/retailer.svg',
                path: 'retailer',
                permission_key: [Permission.VIEW_RETAILER_LIST],
            },
            {
                id: 1,
                name: stockist,
                icon: 'assets/icons/sidebar-icon/stockist.svg',
                path: 'stockist',
                permission_key: [Permission.VIEW_STOCKIST_LIST],
            },
            {
                id: 2,
                name: order,
                icon: 'assets/icons/sidebar-icon/order.svg',
                path: 'order',
                permission_key: [Permission.VIEW_ORDER],
            },
            {
                id: 3,
                name: product,
                icon: 'assets/icons/sidebar-icon/product.svg',
                path: 'product/manufacturer',
                mainPath: 'product',
                permission_key: [
                    Permission.VIEW_MANUFACTURER,
                    Permission.VIEW_BRAND,
                    Permission.VIEW_CATEGORY,
                    Permission.VIEW_PRODUCT_LIST,
                ],
            },
            {
                id: 4,
                name: coupon,
                icon: 'assets/icons/sidebar-icon/coupon.svg',
                path: 'coupon',
                permission_key: [Permission.VIEW_COUPON_LIST],
            },
            {
                id: 5,
                name: gift,
                icon: 'assets/icons/sidebar-icon/gift.svg',
                path: 'gift',
                permission_key: [Permission.VIEW_GIFT],
            },
            {
                id: 6,
                name: admin,
                icon: 'assets/icons/sidebar-icon/admin.svg',
                path: 'admin',
                permission_key: [Permission.VIEW_USER_LIST],
            },
            {
                id: 7,
                name: billing,
                icon: 'assets/icons/sidebar-icon/billing.svg',
                path: 'billing',
                permission_key: [Permission.VIEW_BILLING],
            },
            {
                id: 8,
                name: cms,
                icon: 'assets/icons/sidebar-icon/cms.svg',
                path: 'cms',
                permission_key: [
                    Permission.VIEW_BANNER_LIST,
                    Permission.VIEW_PRODUCT_SHOWCASE_LIST,
                ],
            },
            {
                id: 9,
                name: notification,
                icon: 'assets/icons/sidebar-icon/noti.svg',
                path: 'notification',
                permission_key: [Permission.VIEW_NOTIFICATION_LIST],
            },
            {
                id: 10,
                name: report,
                icon: 'assets/icons/sidebar-icon/report.svg',
                path: 'report',
                permission_key: [Permission.VIEW_REPORT],
            },
        );

        const user = this.userService.deCodeToken();
        user?.realm_access.roles.forEach((role) => {
            const menuFilter = mockMenu.filter((menu) => {
                return menu.permission_key?.includes(role);
            });

            const isAlreadyAdded = this.menu.some(
                (menuItem) => menuItem.id === menuFilter[0]?.id,
            );
            if (menuFilter.length > 0 && !isAlreadyAdded) {
                this.menu.push(menuFilter[0]);
            }
        });
        this.menu = this.menu.sort((a, b) => a.id - b.id);
    }

    ngOnInit(): void {
        this.currentEnv = environment.name
        this.updateCurrentUrl();
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.updateCurrentUrl();
            });
        this.isVersion2 = this.config.getAppVersion() === "v2";
    }

    updateCurrentUrl() {
        const urlSegments = this.router.url
            .split('/')
            .filter((segment) => segment);
        if (urlSegments.length > 1) {
            this.currentUrl = urlSegments.slice(0, 1).join('/');
        } else if (urlSegments.length === 1) {
            this.currentUrl = urlSegments[0];
        } else {
            this.currentUrl = '';
        }
    }

    navigateRoute(path: string) {
        this.router.navigate([`${path}`]);
    }

    toggleVersion() {
        this.isVersion2 = !this.isVersion2;

        if (this.isVersion2) {
            this.config.setAppVersion("v2");
        } else {
            this.config.setAppVersion("v1");
        }

        window.location.reload();
    }
}
